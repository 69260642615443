angular.module('app')
  .constant('getShuffledArray', array => {
    const shuffled = angular.copy(array);
    for (let i = shuffled.length - 1; i > 0; i--) {
      const randomPosition = Math.floor(Math.random() * (i + 1));
      [shuffled[i], shuffled[randomPosition]] = [shuffled[randomPosition], shuffled[i]];
    }
    return shuffled;
  })
  .value('computeAssessmentMaxScore', questions => {
    return questions.reduce((acc, question) => acc += (question.weight || 1), 0);
  })
  .constant('assessmentQuestionsTypes', [
    {
      type: 'QCM',
      label: 'QCM'
    },
    {
      type: 'MATCHING',
      label: 'Matching Question'
    }
  ]);
