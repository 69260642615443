angular.module('app').controller('ManageThemesController', function (
  $state,
  ThemesService,
  UtilsService,
  $i18next,
  GlobalMessagesService
) {
  this.errors = {};
  this.searchThemesForGrid = (config, callback) => {
    return ThemesService.manageable(UtilsService.customParamEzgrid(config))
      .$promise
      .then(themes => callback(themes.content, themes.totalElements, themes.totalElements))
      .catch(() => this.errors.themes = true);
  };

  this.selectTheme = (theme) => $state.go('main.manage.editTheme', {themeId: theme.id});

    this.deleteTheme = (themeId, $index) => {
      ThemesService.listUsersWithTheme({themeId: themeId}).$promise.then((values) => {

        if (values.length === 0) {
          ThemesService.delete({id: themeId}).$promise
            .then(() => GlobalMessagesService.display({
              text: $i18next('manage-themes:action.deleteSucceeded'),
              type: 'success'
            }))
            .then(() => this.themesGridApi.ajax.reload());
        } else {
          GlobalMessagesService.display({text: $i18next('manage-themes:action.themeReferencedMessage'), type: 'danger'})
        }
      }).then(() => this.confirmDeleteModals[$index].hide());
    }
});
