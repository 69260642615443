angular.module('app')
  .config($stateProvider => {
  $stateProvider
    .state('base.legal-notes-fr', {
      url: '/legal-notes-fr',
      $hideLoggin: true,
      templateUrl: './tpl/legal-notes-fr.html',
      controller: function($rootScope, $location){
        $rootScope.$on('languageChanged', function(ev, language){
          $location.path('/legal-notes-' + language.split('-')[0]);
        })
      }
    })
    .state('base.legal-notes-en', {
      url: '/legal-notes-en',
      $hideLoggin: true,
      templateUrl: './tpl/legal-notes-en.html',
      controller: function($rootScope, $location){
        $rootScope.$on('languageChanged', function(ev, language){
          $location.path('/legal-notes-' + language.split('-')[0]);
        })
      }
    });
});
