const HtmlStepEditorComponent = {
  templateUrl: 'html-step-editor.html',
  bindings: {
    api: '=',
    step: '<'
  },
  controller: function ($scope, $sce) {
    if (this.step.data.content == undefined || typeof this.step.data.content === "string") {
      let html = unescape(this.step.data.content);
      this.content = $sce.trustAsHtml(escape(html));
      if(this.step.type == 'QUMU') {
        this.qumuContent = $sce.trustAsHtml(html);
        this.qumuUrl = html && html.split('src=')[1] ? html.split('src=')[1].split(/[ >]/)[0].slice(1, -1) : undefined;
      } else {
        this.videoContent = $sce.trustAsHtml(html);
      }
    } else {
      this.videoContent = $sce.trustAsHtml(html.toString());
    }
    this.api = {
      getData: () => ({content: this.content.toString(), max: 0})
    };

    this.onVideoSelected = (fileId, url) => {
      let videoHtml = `
                <video autoplay controls>
                  <source src="${url}" type="video/mp4" style="height: 100%!important;" />
                </video>`;

      this.videoContent =  $sce.trustAsHtml(videoHtml);
      this.content = $sce.trustAsHtml(escape(videoHtml));
      this.api.data = {content: this.content, max: 0};
    }

    this.onQumuSelected = (fileId, url) => {
      let qumuHtml = `<iframe data-autoplay src="${url}" style="height: 100%!important;width: 54%!important;"></iframe>`;
      this.qumuContent = $sce.trustAsHtml(qumuHtml);
      this.content = $sce.trustAsHtml(escape(qumuHtml));
      this.api.data = {content: this.content, max: 0};
    }
  }
};

angular.module('app')
  .component('htmlStepEditor', HtmlStepEditorComponent);
