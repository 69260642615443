angular.module('app').config($stateProvider => {
  $stateProvider.state('unlogged', {
    abstract: true,
    templateUrl: 'unlogged/unlogged.html',
    controllerAs: '$ctrl',
    controller: function(ezUsers){
      ezUsers.get(() => {
        this.user = ezUsers;
      });
    }
  })
  .state('base', {
    abstract: true,
    templateUrl: 'base/base.html',
    controllerAs: '$ctrl',
    controller: function(ezUsers){
      ezUsers.get(() => {
        this.user = ezUsers;
      });
    }
  })
});
