angular.module('app').controller('ManageGroupsController', function (
  $state,
  GroupsService,
  UtilsService,
  $i18next,
  GlobalMessagesService
) {
  this.searchGroupsForGrid = (config, callback) => {
    return GroupsService.manageable(UtilsService.customParamEzgrid(config))
      .$promise
      .then(groups => callback(groups.content, groups.totalElements, groups.totalElements))
      .catch(() => this.errors.users = true);
  };

  this.selectGroup = (group) => $state.go('main.manage.editGroup', {groupId: group.id});

    this.deleteGroup = (groupId, $index) => {
      let usersPromise = GroupsService.listUsersInGroup({groupId: groupId}).$promise;
      let coursesPromise = GroupsService.listCoursesInGroup({groupId: groupId}).$promise;

      Promise.all([usersPromise, coursesPromise])
        .then(values => {
          if( values[0].length == 0 && values[1].length == 0) {
            GroupsService.delete({id: groupId}).$promise
              .then(() => GlobalMessagesService.display({text: $i18next('manage-groups:action.deleteSucceeded'), type: 'success'}))
              .then(() =>  this.groupsGridApi.ajax.reload());
          } else {
            GlobalMessagesService.display({text: $i18next('manage-groups:action.groupReferencedMessage'), type: 'danger'})
          }
      }).then(() => this.confirmDeleteModals[$index].hide());
    }
});
