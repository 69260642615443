angular.module('app').controller('EditThemeController', function (
  ThemesService,
  UtilsService,
  UsersService,
  CoursesService,
  $stateParams,
  $i18next,
  GlobalMessagesService,
  $rootScope,
  $q,
  $filter
) {
  this.errors = {};

  ThemesService.get({themeId: $stateParams.themeId}).$promise
    .then((response) => {
      this.theme = response;
      this.theme.image.url = $filter('toDownloadFileUrl')(this.theme.image.id);
    });

  ThemesService.listUsersWithTheme({themeId: $stateParams.themeId}).$promise
    .then((response) => this.users = response);

  const flagIfAlreadyAdded = usersPage => {
    usersPage.content = usersPage.content.map(user => {
      user.alreadyAdded = (this.users || [])
        .reduce((acc, userTheme) => acc || userTheme.id === user.id, false);
      return user;
    });
    return usersPage;
  };

  this.searchUsersForGrid = (config, callback) => {
    this.errors.users = false;
    return UsersService.manageable(UtilsService.formatEzgridParams(config))
      .$promise
      .then(flagIfAlreadyAdded, () => $q.reject(this.errors.users = true))
      .then(usersPage => callback(usersPage.content, usersPage.totalElements, usersPage.totalElements))
      .catch(() => this.errors.users = true);
  };

  this.rename = name => ThemesService.rename({themeId: $stateParams.themeId, name}, null).$promise;

  this.addUser = user => ThemesService.addUserToTheme({userId: user.id, themeId: $stateParams.themeId}, "")
    .$promise
    .then(() => this.users.push(user))
    .then(() => this.usersGridApi.ajax.reload())
    .then(() => GlobalMessagesService.display({text: $i18next('edit-theme:success.addUserTheme'), type: 'success'}))
    .catch(() => GlobalMessagesService.display({text: $i18next('edit-theme:errors.addUserTheme'), type: 'danger'}));

  this.removeUser = user => ThemesService.removeUserFromTheme({
    userId: user.id,
    themeId: null
  }, "").$promise
    .then(() => this.users.splice(this.users.indexOf(user), 1))
    .then(() => this.usersGridApi.ajax.reload());

  this.saveCroppedImage = () => $rootScope.$broadcast('crop', {});
  this.changeImage = (id) => ThemesService.changeImage({id: $stateParams.themeId, imageId: id}, null).$promise;
});
