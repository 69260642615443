function goInFullScreen(element) {
    if (element.requestFullscreen)
        element.requestFullscreen();
    else if (element.mozRequestFullScreen)
        element.mozRequestFullScreen();
    else if (element.webkitRequestFullscreen)
        element.webkitRequestFullscreen();
    else if (element.msRequestFullscreen)
        element.msRequestFullscreen();
}
function goOutFullScreen() {
    if (document.exitFullscreen)
        document.exitFullscreen();
    else if (document.mozCancelFullScreen)
        document.mozCancelFullScreen();
    else if (document.webkitExitFullscreen)
        document.webkitExitFullscreen();
    else if (document.msExitFullscreen)
        document.msExitFullscreen();
}
function isFullScreenCurrently() {
    var full_screen_element = document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement || document.msFullscreenElement || null;
    // If no element is in full-screen
    if (full_screen_element === null)
        return false;
    else
        return true;
}
// Number of full step names to display on each side of the current step
const TO_DISPLAY = 0;

const CourseProgressComponent = {
    templateUrl: './course-progress.html',
    bindings: {
        steps: '<',
        currentStep: '<',
        completion: '<',
        courseId: '<',
        stepApi: '<',
        pagination: '@',
        lastVisitedStep: '<'
    },
    controller: function ($stateParams, $timeout, $rootScope, $scope) {
        this.prevDisabled = true;

        this.displayFullStepName = index => {
            return this.steps.length <= 4 || index <= this.currentStep + TO_DISPLAY && index >= this.currentStep - TO_DISPLAY;
        };
        this.canVisitStep = stepIndex => (stepIndex <= this.completion * this.steps.length) && stepIndex >= 0;
        let label = {
            ASSESSMENT: "Question",
            PDF: "Page",
            SLIDES: "Slide",
            HTML: "Video",
            QUMU: "Video"
        }

        this.stepTypeLabel = label[this.steps[this.currentStep].step.type] || "Unknown";
        this.stepType = this.steps[this.currentStep].step.type;
        this.displayPagination = () => {
            switch (this.stepType) {
                case "ASSESSMENT":
                    this.pagination = (this.stepApi.getQuestionIndex() + 1) + "/" + (this.steps[this.currentStep].step.questions.length);
                    break;
                case "PDF":
                    this.pagination = this.stepApi.getPage() + "/" + this.stepApi.getPageCount();
                    break;
                default:
                    this.pagination = "";
                    break;
            }
            this.prevDisabled = (this.getSubStepIndex() === 0 && parseInt($stateParams.stepIndex) === 0) || $stateParams.assessmentId ? true : false;
        };

        this.next = () => {
            if(this.isLastSubStep()){
                this.stepApi.goToNextStep();
            } else {
                this.stepApi.nextSubStep();
            }
        }

        this.prev = () => {
            if (this.getSubStepIndex() === 0) {
                let previousStep = this.steps[this.currentStep-1].step;
                let lastIndexPrevStep;
                switch (previousStep.type) {
                    case "ASSESSMENT":
                        lastIndexPrevStep = this.steps[this.currentStep-1].step.questions.length-1;
                        break;
                    case "PDF":
                        lastIndexPrevStep = 0;
                        break;
                }
                this.stepApi.goToPrevStep(lastIndexPrevStep);
            } else {
                this.stepApi.prevSubStep();
            }
        }
        this.getSubStepIndex = () => {
            let index;
            switch (this.stepType) {
                case "ASSESSMENT":
                    index = this.stepApi.getQuestionIndex();
                    break;
                case "PDF":
                    index = this.stepApi.getPage() - 1;
                    break;
                default:
                    index = 0;
            }
            return index;
        }
        this.isLastSubStep = () => {
            let is;
            switch (this.stepType) {
                case "ASSESSMENT":
                    is = this.stepApi.isLastQuestion();
                    break;
                case "PDF":
                    is = !this.stepApi.getPageCount() ||  this.stepApi.getPageCount() === this.stepApi.getPage();
                    break;
                default :
                    is = true;
            }
            return is;
        }

        $timeout(() => {
            switch (this.stepType) {
                case "PDF":
                  this.disabled = false;
                    this.stepApi.onPdfLoaded(this.displayPagination);
                    break;
                case "ASSESSMENT": {
                  $rootScope.$on("assessmentChanged", () => {
                    this.disabled = (!this.stepApi.state.showAnswer) || $stateParams.assessmentId ? true : false;
                  });
                  $rootScope.$on("assessmentDisplayPagination", () => {
                    this.displayPagination();
                  });

                  break;
                }
                case "SLIDES":
                  this.disabled = false;
                  break;
                case "HTML":
                  this.disabled = true;
                  let video = document.querySelector('video');
                  video.addEventListener('ended', null, false);
                  video.onended = () => {
                    this.disabled = false;
                    $scope.$apply();
                  }
                  this.displayPagination();
                  break;
            }
            this.stepApi.displayPagination = this.displayPagination;
        });
        this.setFullScreen = () => {
            if (isFullScreenCurrently()) {
                goOutFullScreen();
            } else {
                goInFullScreen(document.getElementsByTagName("html")[0]);
            }
        }
        this.getProgressStatus = (index) => {
            var status = this.completion === 1 || index < this.lastVisitedStep ? "100%" : undefined;
            status = status || (index === this.lastVisitedStep ? "In progress" : "0%");

            return status;
        }

      $( document ).ready(function() {
        var menu =  $(".step-info-nav");
        menu.css('top', $(".topheader").height());
      });
    }
};

angular.module('app')
    .component('courseProgress', CourseProgressComponent);
