angular.module('app')
  .factory('StepsService', function ($resource) {
    return $resource('rest/secure/steps/:id', {id: '@id'}, {
      get: {
        method: 'GET',
        isArray: false,
        transformResponse: (response) => {
          if (typeof response === "string") {
            response = JSON.parse(response);
          }
          if (response.type === 'SLIDES') {
            response.data.slides.forEach(slide => slide.content = unescape(slide.content));
          }
          return response;
        },
        url:
          'rest/secure/steps/:id'
      },
      getForCourse: {
        method: 'GET',
        url:
          'rest/secure/courses/:id/steps',
        headers:
          {
            "X-Frame-Options":
              'SAMEORIGIN'
          },
        isArray: true,
        transformResponse: (response) => {
          if (typeof response === "string") {
            response = JSON.parse(response);
          }
          response.map((resp) =>
            resp.step.type === 'SLIDES' ?
              resp.step.data.slides.map((slide) => slide.content = unescape(slide.content)) : resp);
          return response;
        },
      },
      manageable: {
        method: 'GET', url:
          'rest/secure/management/steps'
      },
      save: {
        method: 'POST',
        url:
          'rest/secure/management/steps',
        transformRequest: function (data) {
          if (data.type === 'SLIDES') {
            data.data.slides.forEach(slide => slide.content = escape(slide.content.toString()));
          }
          return  angular.toJson(data);;
        },
        transformResponse: (response) => {
          if (typeof response === "string") {
            response = JSON.parse(response);
          }
          if (response.type === 'SLIDES') {
            response.data.slides.forEach(slide => slide.content = unescape(slide.content));
          }
          return response;
        }
      },
      delete:
        {
          method: 'POST', url:
            'rest/secure/management/steps/:id/delete'
        },
      update: {
        method: 'POST',
        url:
          'rest/secure/management/steps/:id',
        transformRequest:
          function (data) {
            if (data.type === 'SLIDES') {
              data.data.slides.forEach(slide => slide.content = escape(slide.content.toString()));
            }
            return angular.toJson(data);
          },
        transformResponse: (response) => {
          if (typeof response === "string") {
            response = JSON.parse(response);
          }
          if (response.type === 'SLIDES') {
            response.data.slides.forEach(slide => slide.content = unescape(slide.content));
          }
          return response
        }
      },
      getAssessment: {
        method: 'GET',
        url:
          'rest/secure/course/:courseId/step/:stepId/assessment/:questionId',
        responseType:
          "json",
        transformResponse:
          (response) => {
            //Fix for IE
            if (typeof response === "string") {
              response = JSON.parse(response);
            }
            return {
              data: response
            }
          }
      },
      validateUserAnswer: {
        method: 'POST',
        url:
          'rest/secure/course/:courseId/step/:stepId/question/:questionId/validate',
        responseType:
          "json",
        transformResponse:
          (response) => {
            return {data: response};
          }
      }
    });
  });
