const starRating = ({
  templateUrl: './star-rating.html',
  bindings: {
    progress: '=',
    stars: '@'
  },
  controller(){
    this.stars = new Array(5);
    this.isNumber = angular.isNumber;
  }
});

angular.module('app').component('starRating', starRating);

