const CONFLICT = 409;

angular.module('app').controller('CreateGroupController', function (
  $state,
  GroupsService
) {
  this.group = {
    users: []
  };

  this.resetErrors = () => {
    this.groupNameTaken = false;
    this.unknownError = false;
  };

  this.createGroup = () => {
    this.resetErrors();
    return GroupsService.create(this.group.name).$promise
      .then(groupCreated => {
        $state.go('main.manage.editGroup', {groupId: groupCreated.id})
      })
      .catch(error => {
        if (error.status === CONFLICT) {
          this.groupNameTaken = true;
        } else {
          this.unknownError = true;
        }
      });
  };
});
