const NewPasswordController = function($location, $i18next, ezUsers, UsersService, GlobalMessagesService) {
  const search = $location.search();

  this.user = {
    username: search.u,
    token: search.t
  };

  this.isConfirmEqualToPassword = () =>
    !this.newPasswordForm.password.$viewValue || this.newPasswordForm.password.$viewValue === this.passwordConfirm;
  this.setNewPassword = user => {
    if (!this.newPasswordForm.$valid) {
      return;
    }
    UsersService.changePassword(user).$promise
      .then(() => GlobalMessagesService.display({text: $i18next('newpassword:success.changePassword'), type: 'success'}))
      .then(() => ezUsers.login(this.user.username, user.password, false))
      .then(() => {
        $location.path('/');
        $location.search('t', null);
        $location.search('u', null);
      }).catch(
        error => {
          if (error.status === 410 && error.data.user === "alreadyActivated") {
            return GlobalMessagesService.display({text: $i18next('newpassword:errors.alreadyActivated'), type: 'danger'})
          }
          if (error.status === 410 && error.data.token === "invalid") {
            return GlobalMessagesService.display({text: $i18next('newpassword:errors.invalidToken')})
          }
          return GlobalMessagesService.display({text: $i18next('newpassword:errors.global'), type: 'danger'})
        }
      );
  };
};

angular.module('app')
  .controller('NewPasswordController', NewPasswordController);
