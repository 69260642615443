/**
 * @Filter rebaseFilesRelativeUrl
 * Filter that Rebases relative URLs of linked images to absolute in markdown. This is necessary because the relative
 * link won't work
 * in the context of the iframe (which is data-url, so relative URLs convey no meaning in this case) generated by the
 * slider viewer.
 *
 * sample input:
 * <code>
 *     defined Look at those godamn pictures ![image](/rest/secure/files/25/download)
 *     B I H quote list list link pic preview help
 *     ![image](http://localhost:3000/rest/secure/files/6/download)
 * </code>
 *
 * should output something like:
 * <code>
 *     defined Look at those godamn pictures ![image](http://localhost:3000//rest/secure/files/25/download)
 *     B I H quote list list link pic preview help
 *     ![image](http://localhost:3000/rest/secure/files/6/download)
 * </code>
 */
const rebaseFilesRelativeUrl = (baseUrl, UtilsService) => {
  const replacementPattern = UtilsService.uploadedFilesPattern.replace('{ID}', '$1');
  return input => input.replace(UtilsService.uploadedFilesRegex, baseUrl + replacementPattern);
};
angular.module('app')
  .filter('rebaseFilesRelativeUrl', rebaseFilesRelativeUrl);
