const exportData = ({
    bindings: {
        api: '<',
        filename: '@',
        label: '@'
    },
    templateUrl: './export-data.html',
    controller: function() {
        this.doExport = function() {

          let headers;
          let rows;
          let separator = "; ";
          let fileName = this.filename+".csv";
          let csvContent = "";
            headers = this.api.settings()[0].aoColumns.map((col) => {
                var colName = col.nTh.textContent;
                return colName;
            });
            rows = this.api.settings()[0].aoData;
            csvContent += headers.filter(v => v !== "").join(separator) + '\n';
            rows.forEach((row) => {
                csvContent += row._aFilterData.map((column) => column.replace(/,/g, ' ')).join(separator) + '\n';
            });
            csvContent += '\n';
            let blob = new Blob([csvContent],{
                type:'text/csv;charset=utf-8;'
            });
            let oURL = window.URL.createObjectURL(blob);

            //For IE
            if(navigator.msSaveBlob){
                navigator.msSaveBlob(blob,fileName);
            } else {
                let a = document.createElement("a");
                a.setAttribute("href", oURL);
                a.setAttribute("download", fileName);
                a.click();
                a.remove();
            }
            window.URL.revokeObjectURL(oURL);
        };
    }
});
angular.module('app').component('exportData', exportData);
