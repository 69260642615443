const generateEmptyPair = () => ({
    leftValue: '',
    rightValue: ''
});
const generateEmptyQuestion = () => ({
    title: '',
    answers: [
        generateEmptyPair(),
        generateEmptyPair(),
        generateEmptyPair(),
        generateEmptyPair()
    ]
});

const QcmEditorComponent = {
  templateUrl: './matching-question-editor.html',
  require: '^form',
  bindings: {
    questionData: '<question',
    api: '='
  },
  controller: function() {
    const isQuestionPristine = (question) => {
      try {
        return !angular.isArray(question.answers);
      } catch (testingObjectChainsIsAnnoying) { return true; }
    };

    this.buildQuestion = () => {
        this.question = this.questionData;
        if (isQuestionPristine(this.question)) {
            _.extend(this.question, generateEmptyQuestion());
        }
    };

    this.$onInit = this.$onChanges = changes => {
      if (!changes || changes.questionData) {
        this.buildQuestion();
      }
    };
    this.removePair = pairIndex => {
      if (this.question.answers.length > 2) {
        this.question.answers.splice(pairIndex, 1);
      }
    };
    this.addPair = () => this.question.answers.push(generateEmptyPair());
    this.api = {
      getData: () => this.question,
      getType: () => 'MATCHING'
    };
  }
};

angular.module('app')
  .component('matchingQuestionEditor', QcmEditorComponent);
