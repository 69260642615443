const generateEmptyAnswer = () => ({
  label: '',
  valid: false
});
const generateEmptyQuestion = () => ({
    title: '',
    answers: [
      generateEmptyAnswer(),
      generateEmptyAnswer(),
      generateEmptyAnswer(),
      generateEmptyAnswer()
    ],
    weight: 1
});

const QcmEditorComponent = {
  templateUrl: './qcm-editor.html',
  require: '^form',
  bindings: {
    questionData: '<question',
    api: '='
  },
  controller: function($q) {
    const isQuestionPristine = question => {
      try {
        return !angular.isArray(question.answers);
      } catch (BernardDeLaVillardière) { return true; }
    };

    this.$onInit = this.$onChanges = changes => {
      if (!changes || changes.questionData) {
        this.question = JSON.parse(angular.toJson(this.questionData)); // removes angular's $$ properties
        if (isQuestionPristine(this.question)) {
          this.question = generateEmptyQuestion();
        }
      }
    };
    this.removeAnswer = answerIdx => this.question.answers.splice(answerIdx, 1);
    this.addAnswer = () => this.question.answers.push(generateEmptyAnswer());
    this.addImageInAnswer = answer => {
      return this.answerUploaderApi.showDialog().then(fileData => {
        answer.image = fileData.$url;
        answer.$image = fileData.$file;
      });
    };

    this.api = {
      getData: () => {
        return $q.resolve(this.question);
      },
      getType: () => 'QCM'
    };

    this.deleteImage = (image) => {
      this.question.image = null;
      this.question.imageSize = null;
    }
  }
};

angular.module('app')
  .component('qcmEditor', QcmEditorComponent);
