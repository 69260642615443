const SliderEditorDirective = ($i18next, sliderThemes, Upload, toDownloadFileUrlFilter, GlobalMessagesService) => {
  return {
    restrict: 'E',
    templateUrl: './slider-editor.html',
    scope: {
      slidesSource: '<slides',
      theme: '<',
      api: '='
    },
    link: scope => {
      scope.themes = sliderThemes.getAvailableThemes();
      scope.$watch('slidesSource', () => scope.slides = scope.slidesSource || [{}]);
      scope.addSlide = () => scope.slides.push({});
      scope.uploadFileThenAddItAsPicture = () => {
        Upload.upload({
          url: 'rest/secure/management/files',
          data: {file: scope.image}
        }).then(response => {
          const fileId = response.data.id;
          const url = toDownloadFileUrlFilter(fileId);
          GlobalMessagesService.display({text: $i18next('slider:form.success.uploadSucceeded'), type: 'success'});
          // Let's add a link to the downloaded file in the last slide
          scope.slides[scope.slides.length - 1].content += `\n![image](${url})`;
        }, ()  => GlobalMessagesService.display({text: $i18next('slider:form.errors.uploadFailed'), type: 'danger'}));
      };
      scope.extraActionsForSlide = (slide) => {
        return [
          {
            name: 'insert-a-file',
            action: function customFunction() {
              scope.uploaderApi.showDialog().then( ({$file, $url}) => {
                if ($file.mimeType.match(/^image\//i)) {
                  slide.content += `\n![image](${$url})`;
                } else if ($file.mimeType.match(/^video\//i)) {
                  slide.content += `
<video autoplay controls>
  <source src="${$url}" type="${$file.mimeType}" />
</video>`;
                } else {
                  slide.content += `<a href="${$url}">${$file.name}</a>`;
                }
              } ).catch( error => console.error('not uploaded', error));
            },
            className: 'fa fa-upload',
            title: 'Upload a file',
          },
          {
            name: 'reveal-preview',
            action: function toggleRevealPreview() {
              scope.$apply(() => slide.$preview = !slide.$preview);
            },
            className: 'fa fa-eye',
            title: 'Preview slide'
          }
        ];
      };
      scope.api = {
        getData() {
          const slides = scope.slides.filter(s => s.content);
          const theme = scope.theme;
          return {slides, theme, max: 0};
        }
      };
    }
  };
};


angular.module('app')
  .directive('sliderEditor', SliderEditorDirective);
