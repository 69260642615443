const isStepPresentIn = courseSteps => courseProgress => courseSteps.some(cs => cs.step.id === courseProgress.step.id);

angular.module('app')
  .config(($stateProvider, SecurityProvider) => {
        $stateProvider
      .state('main.coursesList', {
        url: 'courses',
        templateUrl: './courses-list/courses-list.html',
        resolve: {
          security: SecurityProvider.hasAnyRole(['CLIENT'], '/management/courses')
        }
      })
      .state('main.playCourse', {
        abstract: true,
        url: 'courses/:courseId/',
        templateUrl: './play-course/play-course.html',
        controller: 'PlayCourseController as PlayCourse',
        resolve: {
          userCourse: ($stateParams, CoursesService) => {
            return (
              CoursesService
                .courseWithCompletion({courseId: $stateParams.courseId})
                .$promise
                .then(userCourse => userCourse.toJSON())
            );
          },
          courseSteps: ($stateParams, StepsService) =>  StepsService
                .getForCourse({id: $stateParams.courseId})
                .$promise
                .then(courseSteps => courseSteps.map(s => s.toJSON())),
          courseStepsProgress: ($stateParams, ProgressService, courseSteps, hasAnyRole, UtilsService) => {
            return ProgressService.courseProgress({courseId: $stateParams.courseId}).$promise
              .then(courseProgresses => UtilsService.filter(courseProgresses, isStepPresentIn(courseSteps)))
          }
        }
      })
      .state('main.playCourse.continue', {
        url: 'continue',
        controller: function($state, userCourse, courseSteps) {
          const lastVisitedStep = userCourse.stepIndex;
          $state.go('main.playCourse.step', {
            courseId: userCourse.course.id,
            stepIndex: lastVisitedStep
          }, {location: 'replace'});
        }
      })
      .state('main.playCourse.step', {
        url: 'steps/:stepIndex/:assessmentId?',
        templateUrl: './play-course/play-step.html',
        controller: 'PlayStepController as $ctrl',
        resolve: {
          cheatersBeThwarted: ($log, $stateParams, userCourse, courseSteps) => {
            const lastVisitedStep = Math.round(userCourse.completion * courseSteps.length);
            if (parseInt($stateParams.stepIndex, 10) > lastVisitedStep) {
              $log.error('you cannot reach this step yet. Finish the previous steps first.');
              throw 'watcha think you` doin?';
            }
          },
          step: ($stateParams, courseSteps, userCourse) => {
            const lastVisitedStep = Math.round(userCourse.completion * courseSteps.length);
            let currentStep = courseSteps[parseInt($stateParams.stepIndex, 0)].step;
                currentStep.lastVisitedStep = lastVisitedStep;

            return currentStep;
          }
        }
      })
      .state('main.playCourse.retry', {
        url: 'steps/:stepIndex/retry/:assessmentId',
        controller: function($state){
          $state.go('main.playCourse.step', {
            courseId: $state.params.courseId,
            stepIndex: $state.params.stepIndex,
            assessmentId: $state.params.assessmentId
          }, {location: true});
        }
      })
      .state('main.playCourse.results', {
        url: 'results',
        controller: 'CourseResultsController as $ctrl',
        templateUrl: './course-results/course-results.html'
      })
      .state('main.playCourse.overview', {
        url: 'overview',
        controller: 'CourseOverviewController as $ctrl',
        templateUrl: './course-overview/course-overview.html'
      })

      .state('main.manage.createCourse', {
        url: 'courses/create',
        templateUrl: './create-course/create-course.html'
      })
      //TODO: harmonize Id names in state params (id vs courseId)
      .state('main.manage.courseDetails', {
        url: 'courses/:id',
        templateUrl: './course-details/course-details.html'
      })
      .state('main.manage.courses', {
        url: 'courses',
        templateUrl: './manage-courses/manage-courses.html'
      })
      .state('main.manage.results', {
         url: 'results',
         templateUrl: './manage-results/manage-results.html'
      })
  });
