const UPLOADED_FILES_REGEX = /rest\/secure\/files\/(\d+)\/download\b/g;
const UPLOADED_FILES_PATTERN = 'rest/secure/files/{ID}/download';

/**
 * Utility service
 */
angular.module('app')
  .service('UtilsService', function () {
    const formatEzgridParams = function (config) {
      return {
        page: config.start / config.length,
        size: config.length,
        sortDir: config.order[0].dir,
        sortBy: config.columns[config.order[0].column].name,
        search: config.search.value
      };
    };

    const customParamEzgrid = function (config) {
      return {
        page: config.start / config.length,
        size: config.length,
        sort: config.columns[config.order[0].column].name + "," + config.order[0].dir,
        search: config.search.value
      }
    };

    const filter = function (array, predicate) {
      let filteredArray = [];
      if (typeof array === "string") {
        array = JSON.parse(array);
      }
      let index = 0;
      array.forEach((val) => {
        if (val && predicate(val)) {
          filteredArray[index] = val;
          index ++;
        }
      });
      return filteredArray;
    };

    const find = function (array, predicate) {
      let result = {};
      if (typeof array === "string") {
        array = JSON.parse(array);
      }
      array.forEach(val => {
        if (val && predicate(val)) {
          result = val;
        }
      });
      return result;
    };

    const mapClientAnswers = function (formAnswers, existingAnswers) {
      let clientAnswer = [];
      if (typeof formAnswers !== "undefined") {
        existingAnswers.forEach((answer, index) => {
          if (formAnswers[index]) {
            clientAnswer[index] = answer;
          }
        });
      }
      return filter(clientAnswer, entry => entry !== undefined);
    };

    const copyToClipboard = function (text) {
      if (navigator.clipboard != undefined) {//Chrome
        navigator.clipboard.writeText(text).then(function () {
        }, function (err) {
          console.error('Could not copy text: ', err);
        });
      }
      else if(window.clipboardData) { // Internet Explorer
        window.clipboardData.setData("Text", text);
      }
    }

    return {
      formatEzgridParams,
      customParamEzgrid,
      uploadedFilesRegex: UPLOADED_FILES_REGEX,
      uploadedFilesPattern: UPLOADED_FILES_PATTERN,
      mapClientAnswers,
      filter,
      find,
      copyToClipboard
    };
  })
;
