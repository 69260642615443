/**
 * Directive used to display error message in pages
 */
const ErrorMessageDirective = () => ({
  restrict: 'E',
  replace: true,
  templateUrl: './error-message.html',
  scope: {
    message: '@'
  }
});

angular.module('app')
  .directive('errorMessage', ErrorMessageDirective);
