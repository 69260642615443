angular.module('app')
  .config(($stateProvider) => {
    $stateProvider
      .state('main.manage.groups', {
        url: 'groups',
        controller: 'ManageGroupsController as $ctrl',
        templateUrl: './manage-groups/manage-groups.html'
      })
      .state('main.manage.createGroup', {
        url: 'groups/create',
        controller: 'CreateGroupController as $ctrl',
        templateUrl: './create-group/create-group.html'
      })
      .state('main.manage.editGroup', {
        url: 'groups/:groupId',
        controller: 'EditGroupController as $ctrl',
        templateUrl: './edit-group/edit-group.html'
      });
  });
