angular.module('app')
  .factory('UsersService', function($resource, FileSaver) {
    return $resource('rest/secure/users/:id', {id: '@id', username: '@username', token: '@token'}, {
      manageable: {method: 'GET', url: 'rest/secure/management/users'},
      create: {method: 'POST', url: 'rest/secure/management/users'},
      delete: {method: 'DELETE', url: 'rest/secure/management/users/:id/delete'},
      massCreate: {method: 'POST', url: 'rest/secure/management/users/bulk', isArray: true},
      advancedMassCreate: {method: 'POST', url: 'rest/secure/management/users/advancedbulk', isArray: true},
      resetPassword: {method: 'POST', url: 'rest/secure/management/users/:id/reset'},
      forgotPassword: {method: 'POST', url: 'rest/users/:username/reset'},
      changePassword: {
        method: 'POST',
        url: 'rest/users/:username/changepassword',
      },
      changeRole: {method: 'POST', url: 'rest/secure/management/users/:id/role/:role'},
      changeEnabled: {method: 'POST', url: 'rest/secure/management/users/:id/enabled/:enabled'},
      getCourses: {method: 'GET', url: 'rest/secure/management/users/:id/courses', isArray: true},
      getCoursesWithAccess: {method: 'GET', url: 'rest/secure/management/users/:id/courses/access'},
      register: {
        method: 'POST',
        url: 'rest/users/:username/initialize',
        params: {username: '@username'}
      },
      getCertifications: {method: 'GET', url: 'rest/secure/management/certifications', isArray: true},
      generateRegisterLink: {method: "POST", url: 'rest/secure/management/users/createRegisterLink'},
      registerExistingUserToCourseByGeneratedLink: {method: "POST", url: 'rest/register-existing-user/:token'},
      registerNewUserToCourseByGeneratedLink: {method: "POST", url: 'rest/register-new-user/:token'},
      downloadDetailedUserStatsAsCsv: {
        method: 'GET', url: 'rest/management/users/detailedStats/csv',
        transformResponse: (response, headers) => {
          let BOM = "\ufeff";
          let data = new Blob([BOM, response], {type: 'text/csv;charset=UTF-8'});
          let filename = headers("content-disposition").split("; ")[1].split("=")[1];
          FileSaver.saveAs(data, filename);
          return response;
        }
      },
      downloadUserStatsAsCsv: {
        method: 'GET', url: 'rest/management/users/stats/csv',
        transformResponse: (response, headers) => {
          let BOM = "\ufeff";
          let data = new Blob([BOM, response], {type: 'text/csv;charset=UTF-8'});
          let filename = headers("content-disposition").split("; ")[1].split("=")[1];
          FileSaver.saveAs(data, filename);
          return response;
        }
      },
      changeLanguage: {method: "POST", url: 'rest/secure/management/users/:id'}
    });
  })
;
