/**
 * Security service
 */
angular.module('app')
  .provider('Security', function() {
    /**
     * Redirects user to homepage if it has not the proper role
     * @param roles array of roles
     * @param location location to where the user should be redirected
     * @returns {Function}
     */
    this.hasAnyRole = (roles, location = '/courses') => {
      return (ezUsers, $location, $q, hasAnyRole) => {
        const deferred = $q.defer();
        ezUsers.get(user => {
          if (user && hasAnyRole(roles)) {
            deferred.resolve();
          }
          else {
            //$location.path(location);
            deferred.reject();
          }
        });
        return deferred.$promise;
      };
    };
    this.$get = () => this;
  })
  .factory('asyncHasAnyRole', (ezUsers, $location, $q) => {
    return roles => {
      const deferred = $q.defer();
      ezUsers.get(user => {
        if (!user || roles.indexOf(user.role) === -1) {
          deferred.reject(`role not found; ${roles}`);
        } else {
          deferred.resolve();
        }
      });
      return deferred.promise;
    }
  });
