angular.module('app')
  .config($stateProvider => {
    $stateProvider
      .state('base.cookies-fr', {
          url: '/cookies-fr',
          $hideLoggin: true,
          templateUrl: './tpl/cookies-fr.html',
          controller: function($rootScope, $location){
              $rootScope.$on('languageChanged', function(ev, language){
                $location.path('/cookies-' + language.split('-')[0]);
              })
          }
        })
        .state('base.cookies-en', {
          url: '/cookies-en',
          $hideLoggin: true,
          templateUrl: './tpl/cookies-en.html',
          controller: function($rootScope, $location){
            $rootScope.$on('languageChanged', function(ev, language){
              $location.path('/cookies-' + language.split('-')[0]);
            })
          }
        });
  });
