const MIN_DELAY_BEFORE_ANIMATION = 300;
const DEFAULT_SUCCESS_THRESHOLD = 80;

angular.module('app').controller('CourseResultsController', function (
  $q,
  $timeout,
  ProgressService,
  CoursesService,
  computeAssessmentMaxScore,
  courseSteps,
  UtilsService,
  StepsService,
  $state,
  $stateParams
) {

  this.hasPassed = false;
  const allProgress = ProgressService.courseProgress({courseId: $stateParams.courseId});

  const loadUserCourseResult = CoursesService.courseWithCompletion({courseId: $stateParams.courseId}).$promise
    .then((response) => {
        this.userCourse = response.toJSON();

        this.hasRetry = this.userCourse.course.possibleAttempts ? this.userCourse.course.possibleAttempts > this.userCourse.retryNumber : false;
        this.hideResults = hiddenCourses.indexOf(this.userCourse.course.id) > -1;
        this.successThreshold = angular.isNumber(this.userCourse.course.successThreshold) ? this.userCourse.course.successThreshold : DEFAULT_SUCCESS_THRESHOLD;

        ProgressService.getCourseResult({courseId: this.userCourse.course.id}).$promise.then((response) => this.userResult = response);

        StepsService.getForCourse({id: $stateParams.courseId}).$promise
          .then(courseSteps => {
            this.onlyTrainings = UtilsService.filter(courseSteps, cs =>  cs.step.type == 'ASSESSMENT')[0] ? false : true;
            resultsLoadedPromise();
          })


      }
    );

  // Why would we need to hide results ? To work around clients not wanting to register their user
  // FIXME: refactor me into a clean solution or ditch me
  const hiddenCourses = [
    51, // Euro iStoxx EWC 50 Index Video
    210, // Autocall on Euro iStoxx EWC 50 Index
    232, // Euro iStoxx EWC 50 Index Video - Spain
    233, // AUTOCALL ON EURO iSTOXX EWC 50 INDEX - Spain
    721, // UAT horizon
    70, // Prod ECOFI SRI Note - FR
    91, // Prod ECOFI SRI Note - NL
    50, // Prod EuroStoxx Dynamic - FR
    90 // Prod EuroStoxx Dynamic - NL
  ];


  const findScoreAndRejected = stepId => {
    const progress = allProgress.$promise.then((result) => {
      UtilsService.filter(result, progress => progress.step.id === stepId)[0];
    });
    return progress && {
      score: progress.score || 0,
      rejected: !!progress.rejected
    };
  };

  this.retry = () => {
    ProgressService.resetProgress({courseId: this.userCourse.course.id}).$promise
      .then($state.go('main.playCourse.step', {courseId: this.userCourse.course.id, stepIndex: 0}));
  }

  this.redirectToRetry = (courseId, stepId, questionId) => {
    let stepData = UtilsService.find(courseSteps, courseStep => courseStep.step.id === stepId);
    let stepIndex = stepData.position - 1;
    $state.go('main.playCourse.retry', {courseId: courseId, stepIndex: stepIndex, assessmentId: questionId});
  }

  const resultsLoadedPromise = () => {
    allProgress.$promise.then(() => {
      this.results = UtilsService.filter(courseSteps, courseStep => courseStep.step.type == 'ASSESSMENT').map(courseStep => {
        const {score, rejected} = findScoreAndRejected(courseStep.step.id);
        return ({
          position: courseStep.position,
          stepName: courseStep.step.name,
          score,
          rejected,
          max: computeAssessmentMaxScore(courseStep.step.questions)
        });
      });
      this.score = this.userCourse.score;
      this.max = courseSteps.reduce((acc, courseStep) => acc + courseStep.step.max, 0);

      this.total = {
        score: this.score,
        rejected: this.results.some(result => result.rejected),
        max: this.max,
        goodScorePercentage: this.score,
        badScorePercentage: 100 - this.score
      };
      this.hasPassed =
        //Validate Course requirements
        this.onlyTrainings ||
        (this.score >= this.successThreshold &&
        //Validate per-step requirements
          (!this.total.rejected));

    })
  };

  $q.all([
    loadUserCourseResult,
    $timeout(angular.noop, MIN_DELAY_BEFORE_ANIMATION)
  ]).then(() => this.startAnimation = true);
});
