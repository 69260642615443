const ManageUserController = function(UtilsService, UsersService, baseUrl, getShortLocale, $scope, $q, LanguageService,
                                      GlobalMessagesService, $i18next, GroupsService) {
  this.availableRoles = [
    'CLIENT',
    'EDITOR',
    'MANAGER',
    'SALES',
    'ADMIN'
  ];
  this.errors = {};
  GroupsService.findAllGroups().$promise.then(groups => this.groups = groups);

  $scope.$on("usersLoaded", (ev, data) => {
    UsersService.getCertifications(UtilsService.customParamEzgrid(data.config)).$promise
        .then(resp => {
          let merged = this.mergeContent(data.usr.content, resp);
          data.cb(merged, data.usr.totalElements, data.usr.totalElements);
        });
  });

  this.searchUsersForGrid = (config, callback) => {
    this.config = config;
    this.callback = callback;
    this.errors.users = false;
    this.userData = UsersService.manageable(UtilsService.customParamEzgrid(config))
        .$promise
        .then(usersPage => {
          $scope.$emit("usersLoaded", {"cb": callback, "usr": usersPage, "config":config});
        })
        .catch(() => this.errors.users = true);
    return this.userData;
  };

  this.mergeContent = (a, b) => {
    var merged =  a;
    a.forEach((v, k) => {
      let o = UtilsService.find(b, (element) => {
        return v.username === element.username;
      });
      if(o !== undefined){
        _.extend(merged[k], o);
      }
    });
    return merged;
  }

  this.selectUser = user => {
    this.selectedUser = user;
    this.selectedUser.language = LanguageService.getByValue(user.language);
  }
  this.isSelected = user => this.selectedUser === user;

  this.saveUserChanges = user => UsersService.edit(user).$promise;
  this.changeRole = () => {
    UsersService.changeRole({role: this.selectedUser.role, id: this.selectedUser.id}, null)
      .$promise
      .then(() => GlobalMessagesService.display({text: $i18next('manage-users:success.changeRole') + this.selectedUser.role, type: 'success'}));
  };
  this.changeEnabled = () => UsersService.changeEnabled({enabled: this.selectedUser.enabled, id: this.selectedUser.id}, null);
  this.resetPassword = () => {
    let params = {
      id: this.selectedUser.id
    }
    params.lang = getShortLocale();
    UsersService.resetPassword(params, null)
      .$promise
      .then(() => GlobalMessagesService.display({text: $i18next('manage-users:success.sendResetPasswordEmail'), type: 'success'}));
  }
  this.getStatsForManager = () => UsersService.resetPassword({id: this.selectedUser.id, lang: getShortLocale()}, null).$promise;
  this.getActivationLink = user => `${baseUrl}#/register?t=${user.token}&u=${user.username}`; // use baseUrl ?
  this.languages = LanguageService.languages;
  this.changeLanguage = () => {
    const params = {
      language: this.selectedUser.language.value
    };
    UsersService.changeLanguage({id: this.selectedUser.id}, params)
        .$promise
        .then(() => GlobalMessagesService.display({text: $i18next('manage-users:success.changedLanguage'), type: 'success'}));
  };
  this.downloadDetailedStats = () => UsersService.downloadDetailedUserStatsAsCsv().$promise;
  this.downloadStats = () => {
    let groupId = this.groupId ? this.groupId : null;
    let params = _.extend(UtilsService.customParamEzgrid(this.config), {"groupId": groupId});
    return UsersService.downloadUserStatsAsCsv(params).$promise;
  };

  this.filterByGroup = (group) => {
    this.groupId = group ? group.id : null;
    let params = _.extend(UtilsService.customParamEzgrid(this.config), {"groupId": this.groupId});
    UsersService.manageable(params)
      .$promise
      .then(usersPage => {
        $scope.$emit("usersLoaded", {"cb": this.callback, "usr": usersPage, "config":this.config});
      })
      .catch(() => this.errors.users = true);
  }
  this.deleteUser = (user, $index) => {
    UsersService.delete({id: user.id})
      .$promise
      .then(() => {
        this.confirmDeleteModals[$index].hide();
        GlobalMessagesService.display({text: $i18next('manage-users:success.delete'), type: 'success'});
        })
      .catch(() => {
        this.errors.user = true;
        GlobalMessagesService.display({text: $i18next('manage-users:errors.delete'), type: 'danger'})
        });
    };

};

angular.module('app')
  .controller('ManageUserController', ManageUserController);
