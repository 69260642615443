/**
 * Directive used to change a textarea into a SimpleMDE - a markdown editor
 * @see https://github.com/NextStepWebs/simplemde-markdown-editor
 */
const MarkdownEditorDirective = $window => {
  const SimpleMDE = $window.SimpleMDE;
  return {
    restrict: 'A',
    require: 'ngModel',
    scope: {
      ngModel: '=',
      extraActions: '@'
    },
    link: (scope, element, attrs, ngModelController) => {
      const options = {
        element: element[0],
        hideIcons: ['fullscreen', 'side-by-side'],
        placeholder: attrs.markdownEditorPlaceholder || undefined,
        status: false,
        forceSync: true,
        spellChecker: false,
        parsingConfig: {
          allowAtxHeaderWithoutSpace: true
        },
        toolbar: [
          'bold',
          'italic',
          'strikethrough',
          '|',
          'heading',
          'quote',
          'code',
          '|',
          'unordered-list',
          'ordered-list',
          '|',
          'link'].concat(scope.$parent.$eval(scope.extraActions) || []).concat([
          '|',
          'fullscreen',
          'guide'
        ])
      };
      const simplemde = new SimpleMDE(options);
      // We need to keep track of when the model changes has been triggered by the external
      // editor to avoid infinte loops in this fashion:
      // RTE change => setViewValue => modelChange => update RTE view (adlib)
      let externalChange;
      ngModelController.$render = function() {
        externalChange = true;
        simplemde.value(ngModelController.$viewValue || '');
      };
      const changeListener = () => {
        //trigger change event on paste text
        $(options.element).trigger("change");
        if (externalChange) {
          externalChange = false;
          return;
        }
        ngModelController.$setViewValue(simplemde.value(), 'simplemde');
      };
      simplemde.codemirror.on('change', changeListener);
      scope.$on('$destroy', () => simplemde.codemirror.off(changeListener));
    }
  };
};

angular.module('app')
  .directive('markdownEditor', MarkdownEditorDirective);
