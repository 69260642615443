angular
    .module('app')
    .directive('markdownInterpreter', markdownInterpreter);

function markdownInterpreter() {
    let directive = {
        restrict: 'AE',
        scope: {
            data: '='
        },
        template: '<div ng-bind-html="data" ng-class="{\'no-markdown\':noMarkdown}"></div>',
        link: function (scope, element, attrs) {
            var md = window.markdownit({
                breaks: false,
                html: true
            });
            if(scope.data){
                scope.$watch("data", function(){
                    scope.data = md.render(scope.data.toString());
                    let noMarkdown = element.find("div").children().length === 1 && element.find("div > p").children().length === 0;
                    scope.noMarkdown = noMarkdown;
                });
            }
        }
    };
    return directive;
}
