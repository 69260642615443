const QcmComponent = {
    templateUrl: './qcm.html',
    bindings: {
        question: '<',
        showAnswer: '<',
        currentQuestionNumber: '@',
        questionsCount: '@',
        onFinish: '&',
        api: '=',
        validateAnswer: '<',
        assessmentChanged: '&',
        givenAnswer: '<'
    },
    controller($scope, getShuffledArray, UtilsService) {
        this.formAnswers = [];
        let counterCheckBox = 0;
        const setGoodAnswersOnShuffledAnswers = (goodAnswers) => {
            if (goodAnswers) {
                this.shuffledAnswers.map(shAnswer => {
                    let match = UtilsService.find(goodAnswers, goodAnswer => goodAnswer.label === shAnswer.label);
                    shAnswer.valid = match !== undefined && match.valid !== null ? match.valid : false;
                    shAnswer.id = null;
                    return shAnswer;
                });
            }
        };

        const areAnswersCorrect = (goodAnswers) => {
            let countTrueAnswers = UtilsService.filter(goodAnswers, val => val.valid === true).length;
            let countTrueSelectedAnswers = 0;
            this.shuffledAnswers.forEach((shAnswer, shIndex) => {
                let formAnswerIndex = this.formAnswers[shIndex] === true ? shIndex : null;
                if(formAnswerIndex !== null){
                    let selectedUserAnswer = UtilsService.find(goodAnswers, goodAnswer => goodAnswer.label === shAnswer.label);
                    if(selectedUserAnswer.valid){
                        countTrueSelectedAnswers +=1;
                    } else {
                        countTrueSelectedAnswers -=1;
                    }
                }
            });

            setGoodAnswersOnShuffledAnswers(goodAnswers);
            return (countTrueAnswers === countTrueSelectedAnswers);
        };
        this.$onChanges = function (changes) {
            if (changes.question) {
                this.formAnswers = [];
                this.isChecked = [];
                this.chkClass = [];
                this.shuffledAnswers = getShuffledArray(this.question.answers);
                this.assessmentChanged();
            }
        };
        _.extend(this.api, {
            setGoodAnswersOnShuffledAnswers: setGoodAnswersOnShuffledAnswers,
            getResult: () => {
                return {
                    isAnswerCorrect:areAnswersCorrect,
                    rejected: areAnswersCorrect,
                    clientAnswer: UtilsService.mapClientAnswers(this.formAnswers, this.shuffledAnswers)
                };
            }
        });

        this.isChecked = [];
        this.chkClass = [];
        this.deselectOthersAnswersIfNeeded = answerIndex => {
            if (this.hasMultipleAnswers()) {
                this.isChecked[answerIndex] = !this.isChecked[answerIndex];
                this.chkClass[answerIndex] = this.isChecked[answerIndex] ? "check-" :  "";
                counterCheckBox = this.isChecked[answerIndex] == true ? counterCheckBox + 1 : counterCheckBox - 1;
                $scope.$emit('assessmentDisabledBtn', counterCheckBox == 0 ? true : false);
                return;
            }
            $scope.$emit('assessmentDisabledBtn', false);
            const answerState = this.formAnswers[answerIndex];
            this.formAnswers = this.formAnswers.map((answer, index) => index === answerIndex && answerState);
        };
        this.hasMultipleAnswers = () => {
            return this.question.multipleAnswers || false;
        };
        this.getContextualClass = (answer, index) => {
            let wasClicked = this.formAnswers[index] !== undefined;
            let buttonClass = wasClicked && this.formAnswers[index] !== false ? "danger" : "reverse";
            if(this.givenAnswer){
                for(var i=0; i<this.givenAnswer.length; i++){
                    var entry = this.givenAnswer[i];
                    if(entry.label === answer.label) {
                        buttonClass = entry.valid ? "success" : "danger";
                        this.chkClass[index] = "check-";
                        break;
                    }
                }
            }

            buttonClass = this.showAnswer ? (answer.valid ? 'success' : buttonClass) : 'reverse';
            return buttonClass
        }
    }
};

angular.module('app').component('qcm', QcmComponent);

