angular.module('app')
  .config($stateProvider => {
    $stateProvider
      .state('main.manage.steps', {
        url: 'steps',
        templateUrl: './manage-steps/manage-steps.html'
      })
      .state('main.manage.createStep', {
        url: 'steps/create',
        templateUrl: './create-step/create-step.html'
      })
      .state('main.manage.stepDetails', {
        url: 'steps/:id',
        templateUrl: './step-details/step-details.html'
      })
      .state('main.manage.updateStep', {
        url: 'steps/:id/edit',
        templateUrl: './update-step/update-step.html'
      });
  })
  .constant('stepTypes', [
    {
      type: 'ASSESSMENT',
      label: 'Assessment'
    },
    {
      type: 'PDF',
      label: 'PDF'
    },
    {
      type: 'SLIDES',
      label: 'Slides'
    },
    {
      type: 'HTML',
      label: 'Video'
    },
    {
      type: 'QUMU',
      label: 'Qumu'
    }
  ])
/*  .run((asyncHasAnyRole, stepTypes) => {
    asyncHasAnyRole(['ADMIN']).then(() => {
      return stepTypes.push({
        type: 'HTML',
        label: 'Video',
        hasAuthority: ['ADMIN']
      });
    });
  });*/

