const RegisterController = function($location, $i18next, ezUsers, UsersService, GlobalMessagesService, $stateParams, LanguageService, $window, $timeout) {

  this.userIsLoggedIn = false;

  ezUsers.get((response) => {
    if(response && response.username){
    this.userIsLoggedIn = true;
  }});
  const search = $location.search();

  this.sgBourseRegisterToken = "bourse-direct-quiz";
  this.isSGBourse = this.sgBourseRegisterToken == $stateParams.token;
  this.user = {
    username: search.u,
    token: search.t
  };

  if(this.isSGBourse){
    this.checkboxOption = ['subscribe'];
    this.user.subscribe = true;
    LanguageService.setLanguage("frbourse-FRBOURSE");
  }


  this.formType= 'LOGIN'; // formType can be LOGIN/REGISTER
  this.changeForm = (type) => {
    this.formType = null;
    this.formType = type;
    this.user.password = undefined;
    this.passwordConfirm = undefined;
  }

  this.usernameDisable = !Boolean($stateParams.token) && !this.isSGBourse;
  this.isConfirmEqualToPassword = () =>
    !this.registerForm.password.$viewValue || this.registerForm.password.$viewValue === this.passwordConfirm;

  this.register = user => {
    if (!this.registerForm.$valid) {
      return;
    }
    if(this.isSGBourse && !user.accept){
      GlobalMessagesService.display({text: $i18next('register:errors.accept'), type: 'warning'})
      return;
    }

    //    Generate Register link
    //    URL example: http://localhost:3000/#/register/b10cdc7c-87d9-4082-9af9-dd446feb19ef
    if ($stateParams.token) {
      user.token = $stateParams.token;

      if (this.formType === "LOGIN") {
          this.registerExistingUser(user);
      } else {
        this.registerNewUser(user);
      }
      return;
    }

    //    Bulk create`em all - users are created before going to register link and already have access on groups and courses.
    //    From register page they can log in or set password for existing user -> user become active
    //    If user already exists, he will not receive a registration email
    //    URL example: http://localhost:3000//#/register?t=e9836288-e3f0-4167-933e-bfa6d968bf7c&u=daniela.gurau@socgen.com
    if (this.formType === "LOGIN") {
      if (!this.userIsLoggedIn) {
        ezUsers.login(this.user.username, user.password, false)
          .then(() => GlobalMessagesService.display({text: $i18next('register:success.login'), type: 'success'}))
          .then(() => $location.path('/courses'))
          .catch(() => GlobalMessagesService.display({
            text: $i18next('register:errors.registerInvalidUsernameOrPasswordException'),
            type: 'warning'
          }));
      }
    } else {
        this.activateUserAndSetPassword(user);
    }
  };

  this.registerExistingUser = (user) => {
    UsersService.registerExistingUserToCourseByGeneratedLink(user).$promise
      .then(() => {
        ezUsers.login(this.user.username, user.password, false)
            .then(() => GlobalMessagesService.display({text: $i18next('register:success.login'), type: 'success'}))
            .then(() => $location.path('/courses'))
            .catch(() => GlobalMessagesService.display({
              text: $i18next('register:errors.InvalidUsernameOrPasswordException'),
              type: 'warning'
            }));
        },
    error => {
      if (error.status === 410 && error.data.user === "alreadyActivated") {
        return ezUsers.login(this.user.username, user.password, false)
          .then(() => $location.path('/courses'))
          .catch(() => {
          GlobalMessagesService.display({text: $i18next('register:errors.alreadyActivated'), type: 'warning'})
          $location.path('/login');});
      } else if ((error.status === 410 && error.data.token === "invalid") || error.data.message === 'INVALID_TOKEN') {
        return GlobalMessagesService.display({text: $i18next('register:errors.invalidToken')})
      }
      else if (error.data.message === 'INVALID_USERNAME_OR_PASSWORD') {
        return GlobalMessagesService.display({text: $i18next('register:errors.registerInvalidUsernameOrPasswordException')})
      }
      else{
        GlobalMessagesService.display({text: $i18next('register:errors.register'), type: 'danger'})
      }
  }
  );}

  this.registerNewUser = (user) => {
    UsersService.registerNewUserToCourseByGeneratedLink(user).$promise
      .then(() => GlobalMessagesService.display({text: $i18next('register:success.register'), type: 'success'}))
      .then(() => {ezUsers.login(this.user.username, user.password, false).then(() => $location.path('/courses'));
  },
    error => {
      if (error.status === 410 && error.data.user === "alreadyActivated") {
        return ezUsers.login(this.user.username, user.password, false)
          .then(() => $location.path('/courses'))
          .catch(() => {
          GlobalMessagesService.display({text: $i18next('register:errors.alreadyActivated'), type: 'warning'});
          $location.path('/login');
      });
      } else if ((error.status === 410 && error.data.token === "invalid") || error.data.message === 'INVALID_TOKEN') {
        return GlobalMessagesService.display({text: $i18next('register:errors.invalidToken')})
      }
      else if (error.data.message === 'USER_ALREADY_EXISTS') {
        return GlobalMessagesService.display({text: $i18next('register:errors.UserAlreadyExistsException')})
      }
      else{
        GlobalMessagesService.display({text: $i18next('register:errors.register'), type: 'danger'})
      }
    }
  );}

  this.activateUserAndSetPassword = (user) => {
    UsersService.register(user).$promise
      .then(() => GlobalMessagesService.display({text: $i18next('register:success.register'), type: 'success'}))
      .then(() =>
          ezUsers.login(this.user.username, user.password, false)
            .catch(() => GlobalMessagesService.display({text: $i18next('register:errors.InvalidUsernameOrPasswordException'),type: 'warning'}))
      )
      .then(() => $location.path('/courses'))
      .catch(error => {
        if (error.status === 404) {
          return GlobalMessagesService.display({text: $i18next('register:errors.InvalidUsernameOrPasswordException')})
        }
        if (error.status === 410 && error.data.user === "alreadyActivated") {
          return GlobalMessagesService.display({text: $i18next('register:errors.alreadyActivated'), type: 'danger'})
        }
        if (error.status === 410 && error.data.token === "invalid") {
          return GlobalMessagesService.display({text: $i18next('register:errors.invalidToken')})
        }
        GlobalMessagesService.display({text: $i18next('register:errors.register'), type: 'danger'})
      })
  };
};

angular.module('app')
  .controller('RegisterController', RegisterController);
