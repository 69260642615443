/**
 * Improved from https://github.com/i18next/ng-i18next/blob/master/src/directive/directiveBindOnce.js
 * to allow translating element texts rather than using only attributes values.
 */
const boI18nextDirectiveFactory = function($i18next, $compile) {
  return {
    REPLACEMENT: true,

    restrict: 'A',

    scope: false,

    link: (scope, element, attrs) => {
      const newElement = element.clone();

      const attr = attrs.boI18next;
      if (attr) {
        newElement.attr('ng-i18next', '__once__' + attrs.boI18next);
        newElement.removeAttr('bo-i18next');
      }
      else {
        newElement.attr('ng-i18next', '__once__' + element.text());
        newElement.removeAttr('bo-i18next');
      }

      element.replaceWith($compile(newElement)(scope));
    }
  };
};

const boI18nextDecorator = ($delegate) => {
  // NOTE: Note sure if angular 1.5(or prior) broke directive decoration but it doesn't work anymore
  // as suggested in http://angular-tips.com/blog/2013/09/experiment-decorating-directives/
  // We have to remove the orginal directive from the directives array and provide a shiny new
  // replacement.
  return $delegate.filter(directive => directive['REPLACEMENT']);
};

angular.module('app').directive('boI18next', boI18nextDirectiveFactory);
angular.module('app').decorator('boI18nextDirective', boI18nextDecorator);
