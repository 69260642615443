/**
 * @component uploadPreview
 *
 * displays a lame preview of an image.
 *
 * usage:
 * <code>
 *     <upload-preview url="Course.course.image"></upload-preview>
 * </code>
 */
angular.module('app').component('uploadPreview', {
  templateUrl: 'upload-preview.html',
  bindings: {
    url: '='
  }
});
