const PlayCourseController = function(
  $scope,
  $q,
  $stateParams,
  CoursesService,
  StepsService,
  userCourse,
  courseSteps) {
  this.errors = {};
  this.course = userCourse.course;
  this.courseSteps = courseSteps;
  this.currentStep = parseInt($stateParams.stepIndex, 10);
  $scope.$on('assessmentProgress', (event, step, progress) => {
    this.currentStep = parseInt($stateParams.stepIndex, 10) + progress;
  });
};

angular.module('app')
  .controller('PlayCourseController', PlayCourseController);
