angular.module('app').directive('imgResize', function () {
  return {
    templateUrl: 'img-resize.html',
    scope: {
      question: '='
    },
    link: ($scope, $element) => {
      const image = $element.find("img")[0];

      image.onload = function () {
        initCropper(image);
      };

      const initCropper = (image) => {
        let elements;

        const cropper = new Cropper(image, {
          zoomOnWheel: false,
          background: false,
          responsive: true,
          dragMode: 'crop',
          viewMode: 1,
          autoCropArea: 1,
          restore: false,
          modal: false,
          guides: false,
          highlight: false,
          resizable: true,
          movable: false,
          dragCrop: false,
          cropBoxMovable: false,
          strict: false,
          toggleDragModeOnDblclick: false,
          ready() {
            elements = $element.find(".image-container img, .image-container div");
            var cropBoxData = cropper.getCropBoxData();

            var aspectRatio = cropBoxData.width / cropBoxData.height;
            cropper.options.aspectRatio = aspectRatio;

         },
          crop(event) {
            let width =  event.detail.width + "px";
            let height = event.detail.height + "px";

            if (elements) {
              elements.css({
                width: width,
                height: height,
                transform: "none"
              });

              $scope.question.imageSize = JSON.stringify({width: width, height: height});
            }
          }
        });
      };
    }
  }
});
