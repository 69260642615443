angular.module('app')
  .config(($stateProvider, SecurityProvider) => {
    $stateProvider
      .state('main.manage.files', {
        url: 'files',
        templateUrl: './files-management.html',
        controller: 'FilesManagementController as $ctrl'
      });
  })
  .filter('toDownloadFileUrl', () => id => id ? 'rest/secure/files/' + id + '/download' : null)
  .controller('FilesManagementController', function(
    $i18next,
    FilesService,
    GlobalMessagesService,
    Upload,
    UtilsService) {

    this.searchFilesForGrid = (config, callback) => {
      return FilesService.myFiles(UtilsService.customParamEzgrid(config))
        .$promise
        .then(filesPage => callback(filesPage.content, filesPage.totalElements, filesPage.totalElements))
    };

    this.showPreview = true;

    this.deleteFile = (fileId) => {
      FilesService.fileReferences({id: fileId}).$promise.then((response) => {
        this.references = response;

        if(this.references.length == 0) {
          FilesService.deleteFile({id: fileId}).$promise
            .then(() => {
              GlobalMessagesService.display({text: $i18next('files-management:deleteSucceeded'), type: 'success'});
              this.refresh();
            }, () => GlobalMessagesService.display({text: $i18next('files-management:deleteFailed'), type: 'danger'}));
        } else {
          this.notificationDeleteModals.show();
        }
      });
    };

    this.onFileSelected = () => {
      const promise = Upload.upload({
        url: 'rest/secure/management/files',
        data: {file: this.upload}
      }).then(() => {
        GlobalMessagesService.display({text: $i18next('slider:form.success.uploadSucceeded'), type: 'success'});
        this.refresh();
      }, ()  => GlobalMessagesService.display({text: $i18next('slider:form.errors.uploadFailed'), type: 'danger'}));
      return promise;
    };

  });
