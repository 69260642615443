const CONFLICT = 409;

const CreateUserController = function($location, $i18next, UsersService, GlobalMessagesService, getShortLocale,
                                      LanguageService, CoursesService, $stateParams, GroupsService, UtilsService,
                                      ThemesService) {
  this.languages = LanguageService.languages;
  this.user = {enabled: true};
  this.user.language = this.languages[0];

  this.resetUsernameConflict = () => this.usernameTaken = false;
  this.createUser = user => {
    this.resetUsernameConflict();
    this.unknownError = false;
    if (!this.createUserForm.$valid) {
      return;
    }
    user.language = user.language ? user.language.value : null;

    return UsersService.create(user)
      .$promise
      .then(() => $location.url('/management/users'))
      .then(() => GlobalMessagesService.display({text: $i18next('create-user:success.create'), type: 'success'}))
      .catch(error => {
        if (error.status === CONFLICT) {
          this.usernameTaken = true;
        }
        else {
          this.unknownError = true;
        }
      });
  };
  this.bulkUserCreationDto = {
    language: this.languages[0],
    enabled: true
  };
  const whitespaceSplitterRegex = /[\s;\/]+/g;
  this.massCreateUsers = users => {
    const parsedUsers = users.split(whitespaceSplitterRegex);
    this.massResult = parsedUsers;
    return UsersService.massCreate(parsedUsers).$promise.then(response => this.massResult = response)
  };
  this.advancedMassCreateUsers = dto => {
    const actualDto = {
      usernames: dto.usernames.split(whitespaceSplitterRegex),
      courses: dto.courses,
      groups: dto.groups,
      enabled: dto.enabled,
      theme: dto.theme,
      language: dto.language.value
    };
    return UsersService.advancedMassCreate(
      { lang: getShortLocale() },
      actualDto
    ).$promise.then(response => this.massResult = response);
  }
  this.generateRegisterLink = dto => {
    let courseIds = dto.courses ? dto.courses.map(course => course.id).join(";") : undefined;
    let groupIds = dto.groups ? dto.groups.map(group => group.id).join(";") : undefined;

    if(typeof dto !== "undefined"){
      const params = {
        theme: dto.theme ? dto.theme.name : null,
        courseIds: courseIds,
        groupIds: groupIds,
        language: dto.language.value
      };

      UsersService.generateRegisterLink(params).$promise
        .then((response) => {
          let url = $location.absUrl().split('#')[0] + `#/register/${response.token}`;
          this.massResult = url;
        })
        .then(() => UtilsService.copyToClipboard(this.massResult))
        .then( () => GlobalMessagesService.display({text: $i18next('create-user:bulk.copyToClipboard'), type: 'success'}));
    }
  }

  this.loadCourses = $query => CoursesService.findCoursesAutocomplete({search: $query}).$promise;
  this.loadGroups = $query => GroupsService.query({query: $query}).$promise;
  ThemesService.manageable().$promise.then((themes) => this.themes = themes.content);
};

angular.module('app')
  .controller('CreateUserController', CreateUserController);
