const AssessmentComponent = {
    templateUrl: './assessment.html',
    bindings: {
        step: '<',
        onFinish: '&',
        api: '=?',
        preview: '<'
    },
    // TODO: change build to make ng-annotate support shorthand syntax "controller($scope) {"
    // note that ng-annotate is frozen https://github.com/olov/ng-annotate/issues/245
    // The babel plugin might be the solution
    controller: function ($scope, UtilsService, computeAssessmentMaxScore, $window, ProgressService, $stateParams, StepsService, $q, GlobalMessagesService, $rootScope) {
        if(!this.step.questions.length || typeof this.step.questions === "undefined"){
            GlobalMessagesService.display({text: "This question structure is deprecated!", type: 'warning'});
            return;
        }
        this.questionsCount = this.step.questions.length;
        this.state = {
            currentQuestionIndex: this.api && this.api.defaultIndex ? this.api.defaultIndex : 0,
            showAnswer: false,
            rejected: false,
            score: 0,
            getStep: () => this.state.currentQuestionIndex + 1,
            getScoreStep: () => this.state.currentQuestionIndex + (this.state.showAnswer ? 1 : 0),
            getCurrentQuestion: () => {
                let questions = this.step.questions;
                return $stateParams.assessmentId ? UtilsService.find(questions, question => question.id === parseInt($stateParams.assessmentId)) : questions[this.state.currentQuestionIndex];
            },
          steps: [{step: this.step}]
        };
        this.questionApi = {};
        if(this.preview) {
          this.api = {};
        }
        this.hasSingleAnswer = () => {
            let question = this.state.getCurrentQuestion();
            return question.multipleAnswers !== null ? question.multipleAnswers : true;
        };
        this.isLastQuestion = () => (this.state.currentQuestionIndex === this.questionsCount - 1);
        this.validateAnswer = () => {
            const {clientAnswer} = this.questionApi.getResult();
            this.state.clientAnswer = clientAnswer;
            this.questionApi.questionId = this.state.getCurrentQuestion()["id"];
            StepsService.validateUserAnswer({
                courseId: parseInt($stateParams.courseId),
                stepId: this.step.id,
                questionId: this.questionApi.questionId
            }, {}).$promise.then((response) => {
                //set good answers from database.
                this.step.questions
                    .filter(question => question.id === this.questionApi.questionId)
                    .map(question => question.answers = response.data);
                this.questionApi.isAnswerCorrect = this.questionApi.getResult().isAnswerCorrect(response.data);
                this.state.showAnswer = true;
                this.state.clientAnswer.map(answer => {
                    answer.questionId = this.questionApi.questionId;
                    answer.questionPosition = this.state.getCurrentQuestion()["position"];
                    if (typeof answer.valid === "undefined") {
                        answer.valid = this.questionApi.isAnswerCorrect;
                    }
                    return answer;
                });

                ProgressService.getCourseResult({courseId: $stateParams.courseId}).$promise
                    .then(this.handleResponse)
                    .catch(this.handleResponse);
              $scope.$emit("assessmentChanged");
              $scope.$emit("assessmentDisabledBtn", true);

            });
        };
        this.handleResponse = (response) => {
            this.computeScore(response);
            this.state.rejected = this.state.getCurrentQuestion()["mandatory"] || !this.questionApi.isAnswerCorrect;
            this.state.showAnswer = true;
            $scope.$emit('validateAnswer', {
                answers: this.state.clientAnswer,
                questionId: this.questionApi.questionId,
                isAnswerCorrect: this.questionApi.isAnswerCorrect
            });
        };
        this.computeScore = (response) => {
            const isAnswerCorrect = this.questionApi.isAnswerCorrect;
            if (response && UtilsService.find(response, (entry) => entry.step.id === this.step.id) && UtilsService.find(response, (entry) => entry.step.id === this.step.id).score >= 0) {
                let step = UtilsService.find(response, (entry) => entry.step.id === this.step.id);
                this.state.score = step.score ? step.score : 0;
                var previousAnswer = UtilsService.find(response, (entry) => entry.step.id === this.step.id).answers[this.state.currentQuestionIndex];
            }
            const weight = this.state.getCurrentQuestion().weight || 1;
            if (isAnswerCorrect) {
              this.state.score = this.state.score + weight;
            }
        };

        _.extend(this.api, {
            isDone: () => this.isLastQuestion() && this.state.showAnswer,
            setQuestion: (index) => {
                if (index >= 0 && index < this.questionsCount) {
                    this.state.showAnswer = false;
                    this.state.currentQuestionIndex = index;
                    $scope.$emit('assessmentProgress', this.step, this.state.currentQuestionIndex / this.questionsCount);
                    this.formAnswers = [];
                }
            },
            getQuestionIndex: () => {
                return this.state.currentQuestionIndex;
            },
            setScore: (score) => {
                if (this.state.currentQuestionIndex === 0) {
                    this.state.score = 0;
                } else {
                    this.state.score = score;
                }
            },
            getResult: () => ({
                score: this.state.score,
                rejected: this.state.rejected, // TODO: does eliminated makes score 0 ?
                max: computeAssessmentMaxScore(this.step.questions),
                clientAnswer: this.state.clientAnswer
            }),
            nextSubStep: () => {
                this.state.showAnswer = false;
                this.api.setQuestion(this.state.currentQuestionIndex + 1);
                this.formAnswers = [];
                // this.api.displayPagination();
            },
            prevSubStep: () => {
                this.state.showAnswer = false;
                this.api.setQuestion(this.state.currentQuestionIndex - 1);
                this.formAnswers = [];
                // this.api.displayPagination();
            },
            validateAnswer: this.validateAnswer,
            hasSingleAnswer: this.hasSingleAnswer,
            state: this.state,
            isLastQuestion: this.isLastQuestion
        });
        $scope.$emit("assessmentLoaded");

        //---- handle back/next button in navigation
        this.onAssessmentChanged = () => {
            if (!this.api || this.preview) {
                return;
            }
            this.api.state.givenAnswer = [];
            this.api.assessmentLoaded = false;
            this.questionApi.assessmentLoaded = false;
            var defer = $q.defer();
            const questionId = this.state.getCurrentQuestion()["id"];
            StepsService.getAssessment({
                courseId: $stateParams.courseId,
                stepId: this.step.id,
                questionId: questionId,
                assessmentIndex: this.state.currentQuestionIndex
            }).$promise.then((response) => {
                let givenAnswers = response.data.givenAnswers;
                let questionAnswers = response.data.questionAnswers;
                if (givenAnswers.length > 0 && !this.api.retry) {
                    this.state.showAnswer = true;
                    this.api.state.givenAnswer = givenAnswers;
                } else {
                    this.state.showAnswer = false;
                    this.api.state.givenAnswer = [];
                }
                this.questionApi.state = this.api.state;
                this.questionApi.setGoodAnswersOnShuffledAnswers(questionAnswers, this.state.showAnswer);

                this.api.assessmentLoaded = true;
                this.questionApi.assessmentLoaded = true;

                $scope.$emit("assessmentChanged");
                $scope.$emit("assessmentDisplayPagination");
                if (!this.api.retry) {
                    defer.resolve(response);
                }
            });
            return defer.promise;
        }

    }
};

angular.module('app').component('assessment', AssessmentComponent);
