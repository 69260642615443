const PDFComponent = {
  templateUrl: './pdf.html',
  bindings: {
    step: '<',
    api: '=?',
    courseSteps: '<',
    preview: '<'
  },
  controller(ProgressService, $stateParams, $timeout, GlobalMessagesService, $i18next) {
    this.steps = [{step: this.step}];
    if (this.preview) {
      this.api = {};
    }

    let pdfjsLib = window['pdfjs-dist/build/pdf'];
    pdfjsLib.externalLinkTarget = 2;
    pdfjsLib.GlobalWorkerOptions.workerSrc = '//mozilla.github.io/pdf.js/build/pdf.worker.js';
    pdfjsLib.linkService = {externalLinkTarget: pdfjsLib.externalLinkTarget, externalLinkRel: true};

    var pdfDoc = null,
      pageNum = null,
      pageRendering = false,
      pageNumPending = null,
      numPages,
      stepIndex,
      questionIndex,
      courseSteps = this.courseSteps,
      preview = this.preview,
      step = this.step,
      scale = 0.8;


    pdfjsLib.getDocument(this.step.data.link).promise.then(function (pdfDoc_) {
      pdfDoc = pdfDoc_;
      numPages = pdfDoc.numPages;
      pageNum = 1;
      $timeout(() => {
        renderPage(pageNum);
        _onPdfLoadedCallback();
      })
    }, (response) => {console.log("Error {}", response); GlobalMessagesService.display({text: $i18next('pdf:previewFailed'), type: 'danger'})});

    function renderPage(num) {
      pageRendering = true;
      pdfDoc.getPage(num).then(function (page) {
        let canvas = document.getElementById('the-canvas');
        let ctx = canvas.getContext('2d');

        const defHeight = 700;
        var scale = window.innerHeight/defHeight;
        let viewport = page.getViewport({scale: scale});
        canvas.height = viewport.height;
        canvas.width =  viewport.width;

        var renderContext = {
          canvasContext: ctx,
          viewport: viewport
        };
        var renderTask = page.render(renderContext);

        renderTask.promise.then(function () {
          pageRendering = false;
          if (pageNumPending !== null) {
            renderPage(pageNumPending);
            pageNumPending = null;
          }

          return page.getAnnotations();
        }).then(function (annotationData) {

          $("#annotation-layer").css({
            left: canvas.offsetLeft + 'px',
            top: 15 + 'px',
            height: canvas.height + 'px',
            width: canvas.width + 'px'
          });

          pdfjsLib.AnnotationLayer.render({
            viewport: viewport.clone({dontFlip: true}),
            div: $("#annotation-layer").get(0),
            annotations: annotationData,
            page: page,
            linkService: pdfjsLib.linkService
          });

          pageChanged(num);
        });
      });
    }

    window.addEventListener('resize', function(){
        renderPage(pageNum);
    });

    let _onPdfLoadedCallback = () => {};
    _.extend(this.api, {
      /**
       * Tells whether the user has finished reading the PDF.
       *
       * It's not done until the last page is encountered. If there's an error in the way, we return true
       * to avoid locking the user in its course progression.
       */
      isDone: () => {
        if (!pageNum) {
          return true;
        }
        try {
          const currentPage = pageNum;
          return currentPage != null && currentPage >= numPages;
        } catch (ex) {
          console.error(ex);
          return true;
        }
      },
      getResult: () => ({score: 0, max: 0}),

      setPage: (page, render) => {
        this.pageIndex = page;
        if (render && page <= numPages && page > 0) {
          renderPage(this.pageIndex);
        }
      },

      onPdfLoaded: (cb) => {
        if (typeof cb === "function") {
          _onPdfLoadedCallback = cb;
        }
      },

      getPage: () => {
        return pageNum;
      },

      getPageCount: () => {
        return numPages;
      },

      nextSubStep: () => {
        onNextPage();
      },

      prevSubStep: () => {
        onPrevPage();
      }
    });

    function pageChanged(pageIndex) {
      stepIndex = $stateParams.stepIndex;
      questionIndex = pageIndex;
      let completion = Number((($stateParams.stepIndex / courseSteps.length) + ((questionIndex) / numPages / courseSteps.length)).toFixed(3));
      if (pageIndex === numPages) {
        stepIndex++;
        questionIndex = 0;
      }
      if (!preview) {
        ProgressService.updateCompletionInStep({
          courseId: $stateParams.courseId
        }, {
          stepIndex: stepIndex,
          questionIndex: questionIndex,
          stepId: step.id,
          completion: completion
        }).$promise;
      }
      _onPdfLoadedCallback();
    }

    function queueRenderPage(num) {
      if (pageRendering) {
        pageNumPending = num;
      } else {
        renderPage(num);
      }
    }

    function onPrevPage() {
      if (pageNum <= 1) {
        return;
      }
      pageNum--;
      queueRenderPage(pageNum);
    }

    function onNextPage() {
      if (!pdfDoc || !pdfDoc.numPages || pageNum >= pdfDoc.numPages) {
        return;
      }
      pageNum++;
      queueRenderPage(pageNum);
    }
  }
};

angular.module('app')
  .component('pdf', PDFComponent)
  .directive('ezPdfHotkeys', function () {
    return {
      require: 'ezSimplePdfReader',
      restrict: 'A',
      link: function ($scope, $element, $attributes, ezSimplePdfReader) {
        $element.attr('tabindex', '0');
        $element.on('keydown', $event => {
          //stolen from https://github.com/hakimel/reveal.js/blob/bac187f3a0f7e8f92d33ca0258fe2e96553d2930/js/reveal.js
          let triggered = false;

          // 2. System defined key bindings
          if (triggered === false) {

            // Assume true and try to prove false
            triggered = true;

            switch ($event.keyCode) {
              // p, page up
              case 80:
              case 33:
                ezSimplePdfReader.previousPage();
                break;
              // n, page down
              case 78:
              case 34:
                ezSimplePdfReader.nextPage();
                break;
              // h, left
              case 72:
              case 37:
                ezSimplePdfReader.previousPage();
                break;
              // l, right
              case 76:
              case 39:
                ezSimplePdfReader.nextPage();
                break;
              // k, up
              case 75:
              case 38:
                ezSimplePdfReader.previousPage();
                break;
              // j, down
              case 74:
              case 40:
                ezSimplePdfReader.nextPage();
                break;
              // home
              case 36:
                ezSimplePdfReader.firstPage();
                break;
              // end
              case 35:
                ezSimplePdfReader.lastPage();
                break;
              // space
              case 32:
                ezSimplePdfReader.nextPage();
                break;
              // f
              case 70:
                ezSimplePdfReader.fullscreen();
                break;
              default:
                triggered = false;
            }

          }

          // If the input resulted in a triggered action we should prevent
          // the browsers default behavior
          if (triggered) {
            $event.preventDefault();
          }

        });
      },
    };
  });
