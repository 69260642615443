angular.module('app')
  .factory('CoursesService', function($resource,FileSaver) {
    let textHtml = "text/html; charset=utf-8";
    return $resource('rest/secure/courses/:courseId', {courseId: '@courseId', userId: '@userId', coursesIds: '@coursesIds'}, {
      delete: {method: 'POST', url: 'rest/secure/management/courses/:courseId/delete'},
      save: {method: 'POST', url: 'rest/secure/management/courses'},
      manageable: {method: 'GET', url: 'rest/secure/management/courses'},
      coursesWithCompletions: {method: 'GET', url: 'rest/secure/course/:coursesIds/completions', isArray: true},
      courseWithCompletion: {method: 'GET', url: 'rest/secure/course/:courseId/completion'},
      stats: {method: 'GET', url: 'rest/secure/management/course/:courseId/stats'},
      addStepToCourse: {method: 'POST', url: 'rest/secure/management/courses/:courseId/steps'},
      grantAccess: {method: 'POST', url: 'rest/secure/management/courses/:courseId/users/grant'},
      revokeAccess: {method: 'POST', url: 'rest/secure/management/courses/:courseId/users/:userId/revoke'},
      permuteSteps: {method: 'POST', url: 'rest/secure/management/courses/:courseId/steps/permute'},
      rename: {method: 'POST', url: 'rest/secure/management/courses/:courseId/name', headers: {"Content-Type": textHtml}},
      changeSuccessThreshold: {method: 'POST', url: 'rest/secure/management/courses/:courseId/successthreshold/:threshold'},
      changeDescription: {method: 'POST', url: 'rest/secure/management/courses/:courseId/description', headers: {"Content-Type": textHtml}},
      changeImage: {method: 'POST', url: 'rest/secure/management/courses/:courseId/image'},
      changeSummaryFile: {method: 'POST', url: 'rest/secure/management/courses/:courseId/summaryFile'},
      share: {method: 'POST', url: 'rest/secure/management/courses/:courseId/sharings/:groupId'},
      unshare: {method: 'POST', url: 'rest/secure/management/courses/:courseId/sharings/:groupId/delete'},
      addRequirement: {method: 'POST', url: 'rest/secure/management/courses/:courseId/requirement'},
      findCoursesAutocomplete: {method: 'GET', url: 'rest/secure/coursesautocomplete', isArray: true},
      getRequirement: {method: 'GET', url: 'rest/secure/management/courses/:courseId/requirement', isArray: true},
      sharings: {method: 'GET', url: 'rest/secure/management/courses/:courseId/sharings', isArray: true},
      removeStep: {method: 'POST', url: 'rest/secure/management/courses/:courseId/steps/:step/delete'},
      getStudentsByCourse: {method: 'GET', url: 'rest/secure/management/courses/:courseId/users', isArray: true},
      categories: {method: 'GET', url: 'rest/secure/management/courses/:courseId/categories', isArray: true},
      label: {method: 'POST', url: 'rest/secure/management/courses/:courseId/categories/:category'},
      unlabel: {method: 'POST', url: 'rest/secure/management/courses/:courseId/deletecategory/:category'},
      getCoursesByCategory: {method: 'GET', url: 'rest/secure/coursesByCategory'},
      loadCategories: {method: 'GET', url: 'rest/secure/categories', isArray: true},
      getCurrentUser: {method: 'GET', url:'rest/secure/users/statistics'},
      changeFailMessage: {method: 'POST', url: 'rest/secure/management/courses/:courseId/failMessage', headers: {"Content-Type": textHtml}},
      changeSucceedMessage: {method: 'POST', url: 'rest/secure/management/courses/:courseId/succeedMessage', headers: {"Content-Type": textHtml}},
      changeEstimated: {method: 'POST', url: 'rest/secure/management/courses/:courseId/estimated', headers: {"Content-Type": textHtml}},
      changePossibleAttempts: {method: 'POST', url: 'rest/secure/management/courses/:courseId/possibleAttempts', headers: {"Content-Type": textHtml}},
      getAudit: {method: 'GET', url: 'rest/secure/audit/:auditedClass'},
      removeProgress: {method: 'POST', url: 'rest/secure/management/courses/:courseId/users/:userId/removeProgress'},
      deleteImage: {method: 'POST', url: 'rest/secure/management/courses/:courseId/image/delete'},
      deleteSummaryFile: {method: 'POST', url: 'rest/secure/management/courses/:courseId/summaryFile/delete'},
      courseCertification:{method: 'GET', url:"rest/management/course/certification?:coursesIds",
        transformResponse: (response, headers) => {
           let BOM = "\ufeff";
           let data = new Blob([BOM, response], {type: 'text/csv;charset=UTF-8'});
           let filename = headers("content-disposition").split("; ")[1].split("=")[1];
           FileSaver.saveAs(data, filename);
           return response;
          }
      }
    });
  });
