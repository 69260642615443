angular.module('app').factory('loadingInterceptor', ($q, Coordinator) => {
  const LOADING_SYMBOL = '$loadingTracker';
  const coordinator = new Coordinator();

  return {
    request: config => {
      config[LOADING_SYMBOL] = $q.defer();
      coordinator.addPromise(config[LOADING_SYMBOL].promise);
      return config;
    },
    response: response => {
      response.config[LOADING_SYMBOL].resolve();
      return response;
    },
    responseError: rejection => {
      rejection.config[LOADING_SYMBOL].reject();
      return $q.reject(rejection);
    },
    getCoordinator: () => coordinator
  };
}).config($httpProvider => $httpProvider.interceptors.push('loadingInterceptor'));
