angular.module('app')
  .config(($stateProvider, SecurityProvider) => {
    $stateProvider
      .state('main.profile', {
        url: 'profile',
        controller: 'ProfileController as $ctrl',
        templateUrl: './profile.html'
      });
  })
  .controller('ProfileController', function(CoursesService) {

    CoursesService.getCurrentUser().$promise
      .then(response => {
        this.currentUser = response;
      })

  }
);
