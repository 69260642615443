/**
 * This interceptor intercepts every 401 errors and displays the connection form.
 * The request in error is kept in a pending state and is retried once the user has logged in.
 * If this is not a GET request, the request is not retried after the MAX_RETRY_DELAY delay.
 */
angular.module('app')
  .factory('logoutInterceptor', ($q, $injector, $rootScope, $timeout) => ({
    responseError(rejection) {
      if (rejection.status === 401) {
        $injector.invoke(['ezUsers', ezUsers => ezUsers.considerLoggedOut(true)]);
        const deferred = $q.defer();
        const unregister = $rootScope.$on('ezweb.login', () => {
          unregister();
          deferred.resolve();
        });
        return deferred.promise.then(() => $injector.invoke(['$http', $http => $http(rejection.config)]));
      }
      return $q.reject(rejection);
    }
  }));
