const CONFLICT = 409;

const CreateCourseController = function($location, $i18next, CoursesService, GlobalMessagesService) {
  this.resetNameConflict = () => this.nameTaken = false;
  this.createCourse = (course) => {
    this.resetNameConflict();
    this.unknownError = false;
    if (this.createCourseForm.$valid) {
      CoursesService.save(course)
        .$promise
        .then(course => $location.url('/management/courses/' + course.id))
        .then(() => GlobalMessagesService.display({text: $i18next('create-course:success.create'), type: 'success'}))
        .catch(error => {
          if (error.status === CONFLICT) {
            this.nameTaken = true;
          }
          else {
            this.unknownError = true;
          }
        });
    }
  };
};

angular.module('app')
  .controller('CreateCourseController', CreateCourseController);
