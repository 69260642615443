const watchAction = (condition, $scope, $element, $transclude) => {
  let block;
  return value => {
    if (condition(value) === true) {
      if (!block) {
        $transclude($scope, clone => {
          block = {clone};
          $element.after(clone);
        });
      }
    }
    else if (block) {
      block.clone.remove();
      block = null;
    }
  };
};

const expandRole = role => {
  switch(role) {
    case 'SALES':
      return ['SALES', 'EDITOR', 'MANAGER'];
    case 'ADMIN':
      return ['ADMIN', 'EDITOR', 'MANAGER'];
    case '':
      return [];
    default:
      return [role];
  }
};

const HasAnyRoleFactory = ezUsers => roles => {
  const userRoles = expandRole( (ezUsers.role || '').toUpperCase() );
  if (roles && roles.length && userRoles && userRoles.length) {
    return roles.some(role =>  userRoles.some(userRole => userRole == role.toUpperCase()) );
  } else {
    return false;
  }
};

/**
 * Directive used to hide some elements when user has not the proper roles
 */
const HasAnyRoleDirective = (ezUsers, $parse, hasAnyRole) => ({
  restrict: 'A',
  transclude: 'element',
  priority: 10000,
  terminal: true,
  link: ($scope, $element, $attrs, ctrl, $transclude) => {
    const getRoles = $parse($attrs.hasAnyRole);
    const callback = watchAction(() => {
      const roles = getRoles($scope);
      return hasAnyRole(roles);
    }, $scope, $element, $transclude);

    $scope.$watch(() => ezUsers.role, callback);
    $scope.$watch($attrs.hasAnyRole, callback);
  }
});

angular.module('app').factory('hasAnyRole', HasAnyRoleFactory);
angular.module('app').directive('hasAnyRole', HasAnyRoleDirective);
