'use strict';

angular.module('app')
  .config(($stateProvider, $urlRouterProvider) => {
    $stateProvider
      .state('main.home', {
        url: '',
        template: '...',
        controller: ($location, ezUsers, hasAnyRole, LanguageService) => {
          if(!ezUsers.connected){
            if(LanguageService.getCurrentLanguage() === "frbourse-FRBOURSE"){
              LanguageService.setLanguage("fr-FR");
            }
          }
          ezUsers.get(() => {
              $location.path('/courses');
          });
        }
      });
    $urlRouterProvider
      .otherwise('/');
  });
