const createQuestionData = (type, data) => ({type, data});

const AssessmentEditorComponent = {
  templateUrl: './assessment-editor.html',
  require: '^form',
  bindings: {
    step: '<',
    api: '='
  },
  controller: function($q, assessmentQuestionsTypes, computeAssessmentMaxScore) {
    this.$onInit = this.$onChanges = () => {
      this.questions = this.step.questions || [{}];
    };
    this.moveQuestionUp = question => {
      const idx = this.questions.indexOf(question);
      if (idx <= 0) {
        throw 'cannot move this question up';
      }

      [this.questions[idx], this.questions[idx-1]] = [this.questions[idx-1], this.questions[idx]];
      [this.questionsApi[idx], this.questionsApi[idx-1]] = [this.questionsApi[idx-1], this.questionsApi[idx]];
    };
    this.moveQuestionDown = question => {
      const idx = this.questions.indexOf(question);
      if (idx === -1 || idx >= this.questions.length-1) {
        throw 'cannot move the last question down';
      }

      [this.questions[idx], this.questions[idx+1]] = [this.questions[idx+1], this.questions[idx]];
      [this.questionsApi[idx], this.questionsApi[idx+1]] = [this.questionsApi[idx+1], this.questionsApi[idx]];
    };
    this.deleteQuestion = question => {
      const idx = this.questions.indexOf(question);
      if (idx === -1) {
        throw 'cannot delete, question not found';
      }
      this.questions.splice(idx,1);
      this.questionsApi.splice(idx,1);
    };
    this.questionsTypesList = assessmentQuestionsTypes;
    this.addQuestion = () => this.questions = this.questions.concat({});
    this.questionsApi = [];
    this.api = {
      getData: () => {
        const questionsPromises = this.questionsApi
          .filter(angular.identity)
          .map(questionApi => {
            // Partial application of the function with bind
            return $q.when(questionApi.getData())
              .then(data => (angular.merge(data, {type: questionApi.getType()})))
          });
        return $q.all(questionsPromises)
          .then(questions => ({
            questions,
            shuffleQuestions: this.shuffleQuestions
          }));
      }
    };
  }
};

angular.module('app')
  .component('assessmentEditor', AssessmentEditorComponent);
