const STARS_COUNT = 5;

const generateSequence = length => Array.apply(null, {length}).map(Function.call, Number);

angular.module('app').component('stars', {
  templateUrl: './stars.html',
  bindings: {
    score: '<'
  },
  controller: function() {
    this.$onInit = this.$onChanges = () => {
      this.stars = generateSequence(STARS_COUNT)
        .map(index => ({filled: this.score >= (index + 1) / STARS_COUNT}));
    };
  }
});
