const PDFEditorComponent = {
  templateUrl: './pdf-editor.html',
  bindings: {
    data: '<',
    api: '='
  },
  controller: function($scope, $i18next, Upload, GlobalMessagesService, UtilsService) {
    $scope.$watch('data.link', () => this.pdfLink = (this.data || {}).link);
    this.uploadPDFFile = () => {
      if (!this.pdf) {
        return;
      }
      this.pdfLink = undefined;
      Upload.upload({
        url: 'rest/secure/management/files',
        data: {file: this.pdf}
      }).then(response => {
        GlobalMessagesService.display({text: $i18next('pdf:form.messages.uploadSucceeded'), type: 'success'});
        this.pdfLink = UtilsService.uploadedFilesPattern.replace('{ID}', response.data.id);
      }, () => GlobalMessagesService.display({text: $i18next('pdf:form.messages.uploadFailed'), type: 'danger'}));
    };
    this.api = {
      getData: () => {
        return {link: this.pdfLink, max: 0};
      }
    };
  }
};

angular.module('app')
  .component('pdfEditor', PDFEditorComponent);
