angular.module('app').factory('GroupsService', $resource =>
  $resource('rest/secure/management/groups/:groupId', {id: '@id'}, {
    manageable: {method: 'GET', url: 'rest/secure/management/groups'},
    create: {method: 'POST', url: 'rest/secure/management/groups',  headers: {"Content-Type": "text/html; charset=utf-8"}},
    rename: {method: 'POST', url: 'rest/secure/management/groups/:groupId/name/:name'},
    listUsersInGroup: {method: 'GET', url: 'rest/secure/management/groups/:groupId/users', isArray: true},
    addUserToGroup: {method: 'POST', url: 'rest/secure/management/groups/:groupId/users/:userId'},
    removeUserFromGroup: {method: 'POST', url: 'rest/secure/management/groups/:groupId/users/:userId/delete'},
    listCoursesInGroup: {method: 'GET', url: 'rest/secure/management/groups/:groupId/courses', isArray: true},
    addCourseToGroup: {method: 'POST', url: 'rest/secure/management/groups/:groupId/courses/:courseId'},
    removeCourseFromGroup: {method: 'POST', url: 'rest/secure/management/groups/:groupId/courses/:courseId/delete'},
    changeOwner: {method: 'POST', url: 'rest/secure/management/groups/:groupId/changeOwner/:ownerId'},
    findPossibleOwners: {method: 'GET', url: 'rest/secure/management/users/elevatedUsers', isArray: true},
    delete: {method: 'POST', url: 'rest/secure/management/groups/:id/delete'},
    findAllGroups: {method: "GET", url: 'rest/secure/management/groups/allGroups', isArray: true}
  })
);
