angular.module('app').component('htmlStepPlayer', {
  templateUrl: 'html-step-player.html',
  bindings: {
    htmlSource: '=',
    api: '='
  },
  controller($sce, $window, $timeout, $rootScope) {
    this.$onChanges = changes => {
      if (typeof this.htmlSource === "string") {
        this.htmlSource = $sce.trustAsHtml(unescape(this.htmlSource));
      } else {
        this.htmlSource = $sce.trustAsHtml(unescape(this.htmlSource.toString()));
      }

      $timeout(() => this.setVideoHeight());
    };

    _.extend(this.api, {
      isDone: () => true,
      getResult: () => ({score: 0, max: 0})
    });

    this.setVideoHeight = () => {
      let header = $("ez-mini-header")
      let bottomNavigation = $(".bottom-nav");
      let assetsNavigation = $("course-progress");

      let offset = header.outerHeight(true) + bottomNavigation.outerHeight(true) + assetsNavigation.outerHeight(true);
      let videoHeight = $window.innerHeight - offset;
      let factor = 20;
      $(".video-wrapper").children().css({"height": (videoHeight - factor) + "px"});

    }
  }
})
;
