angular.module('app')
  .config($stateProvider => {
  $stateProvider
  .state('base.accessibility-fr', {
    url: '/accessibility-fr',
    $hideLoggin: true,
    templateUrl: './tpl/accessibility-fr.html',
    controller: function($rootScope, $location){
      $rootScope.$on('languageChanged', function(ev, language){
        $location.path('/accessibility-' + language.split('-')[0]);
      })
    }
  })
    .state('base.accessibility-en', {
      url: '/accessibility-en',
      $hideLoggin: true,
      templateUrl: './tpl/accessibility-en.html',
      controller: function($rootScope, $location){
        $rootScope.$on('languageChanged', function(ev, language){
          $location.path('/accessibility-' + language.split('-')[0]);
        })
      }
    });
});
