
angular.module('app')
    .service('LanguageService', function(ezI18nService) {
        const languages = [
            {
                name: "English",
                value: "en-US"
            },
            {
                name: "Français",
                value: "fr-FR"
            }
        ];
        const setLanguage = (lang) => {
            if(lang){
                ezI18nService.changeLanguage(lang);
            }
        }
        const getByValue = (value) => {
            let language = {};
            languages.forEach(lang => {
                if(lang.value === value){
                    language = lang;
                    return;
                }
            });
            return language;
        }
      const getCurrentLanguage = () => {
        return localStorage.getItem("ezweb-i18n.language");
      }
        return {
            languages: languages,
            setLanguage: setLanguage,
            getByValue: getByValue,
            getCurrentLanguage: getCurrentLanguage
        };
    })
;
