const CONFLICT = 409;

const EditStepController = function(
  $scope,
  $window,
  $q,
  $state,
  $stateParams,
  $rootScope,
  $i18next,
  StepsService,
  GlobalMessagesService,
  confirmator,
  stepTypes) {
  StepsService.get({id: $stateParams.id}).$promise.then(step => this.step = step);
  this.errors = {};
  this.resetNameConflict = () => this.errors.nameTaken = false;
  this.types = stepTypes;

  const handleErrors = error => {
    if (error.status === CONFLICT) {
      this.errors.nameTaken = true;
    }
    else {
      this.errors.unknown = true;
    }
  };

  const removeListener = $rootScope.$on('$stateChangeStart', ($event, state, stateParams) => {
    if (!this.editStepForm) return;
    const isDirty = this.editStepForm.$dirty;
    if (!isDirty) {
      return;
    } else {
      $event.preventDefault();
      confirmator.confirm($i18next('update-step:unsavedWarning'))
        .then(() => {
          removeListener();
          $state.go(state.name, stateParams);
        });
    }
  });
  $scope.$on('$destroy', removeListener);


  this.updateStep = step => {

    this.resetNameConflict();
    this.errors.unknown = false;
    if (!this.editStepForm.$valid) {
      return;
    }

    $q.when(this.typeApi.getData())
    .then(data => {
      //angular.merge(step, {data, max: data.max});
        if(step.type !== "ASSESSMENT"){
            step.data = data;
            step.max = data.max;
        } else {
            _.extend(step, data);
        }
      StepsService.update(step)
        .$promise
        .then(step => {
            this.step = step;
            removeListener();
        })
        .then(() => GlobalMessagesService.display({text: $i18next('update-step:success.update'), type: 'success'}))
        .catch(handleErrors);
    });
  };
};

angular.module('app')
  .controller('EditStepController', EditStepController);

