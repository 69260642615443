const ManageStepController = function($state, $i18next, dateFilter, GlobalMessagesService, UtilsService, StepsService) {
  this.errors = {};

  this.deleteStep = (step, $index) => {
    StepsService.delete({id: step.id}).$promise
      .then(() => this.refresh())
      .then(() => this.confirmDeleteModals[$index].hide())
      .catch(() => {
        this.errors.steps = true;
        GlobalMessagesService.display({text: $i18next('manage-steps:errors.delete'), type: 'danger'})
      });
  };
  this.duplicateStep = step => {
    return StepsService.get({id: step.id}).$promise
      .then(wholeStep => {
        delete wholeStep.id;
        // making mention of the date in the new title allows us slackers to not care about what happens when
        // a step is duplicated several times.
        wholeStep.name = $i18next('manage-steps:copyOf', {originalName: wholeStep.name, dupedDate: dateFilter(new Date(), 'medium')});
        return wholeStep;
      })
      .then(wholeStep => StepsService.save(wholeStep).$promise)
      .then(dupeStep => $state.go('main.manage.updateStep', {id: dupeStep.id}))
      .then(() => GlobalMessagesService.display({text: $i18next('manage-steps:success.duplicate'), type: 'success'}))
      .catch(() => {
        this.errors.steps = true;
        GlobalMessagesService.display({text: $i18next('manage-steps:error.duplicate'), type: 'error'});
      });
  };
  this.searchStepsForGrid = (config, callback) => {
    this.errors.steps = false;
    return StepsService.manageable(UtilsService.customParamEzgrid(config))
      .$promise
      .then(stepsPage => callback(stepsPage.content, stepsPage.totalElements, stepsPage.totalElements))
      .catch(() => this.errors.steps = true);
  };
};

angular.module('app')
  .controller('ManageStepController', ManageStepController);
