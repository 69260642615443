const mapCompletionToDictByCourseId = list => {
  activateTooltip();
  return list.reduce(
    (acc, completion) => angular.extend(acc, {[completion.course.id]: completion}),
    {}
  );
}

const activateTooltip = () => {
  $(".wrapper-tooltip").tooltip("destroy");
  setTimeout(() => $(".wrapper-tooltip").tooltip());
}

const CoursesListController = function(UtilsService, CoursesService, $window, $timeout, hasAnyRole, $rootScope) {
  this.errors = {courses: false};
  CoursesService.get()
    .$promise
    .then(coursesPage => this.courses = _.sortBy(coursesPage.content, 'name'))
    .catch(() => this.errors.courses = true)
    .then(courses => courses.map(course => course.id))
    // There's no use requesting completion for no courses, plus it would fail to form a correct URL with no courseIds
    .then(coursesIds => coursesIds.length ? CoursesService.coursesWithCompletions({coursesIds}).$promise : [])
    .then(mapCompletionToDictByCourseId)
    .then(completions => this.completions = completions);

  this.getCurrentUser = CoursesService.getCurrentUser().$promise
    .then(response => {
      this.currentUser = response;
    })
  this.activateTooltip = activateTooltip;

};

angular.module('app')
  .controller('CoursesListController', CoursesListController);
