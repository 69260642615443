const CONFLICT = 409;

const CreateStepController = function($q, $location, $i18next, StepsService, GlobalMessagesService, stepTypes) {
  this.step = {
    data: {}
  };
  this.errors = {};
  this.resetNameConflict = () => this.errors.nameTaken = false;
  this.types = stepTypes;
  const handleErrors = error => {
    if (error.status === CONFLICT) {
      this.errors.nameTaken = true;
    }
    else {
      this.errors.unknown = true;
    }
  };
  this.createStep = step => {
    this.resetNameConflict();
    this.errors.unknown = false;
    if (!this.createStepForm.$valid) {
      return;
    }
    // TODO: this asynchronousness is a pain in the ass, and obsolete now we've fixed the upload problem
    return $q.when(this.typeApi.getData())
      .then(data => {
        if(step.type !== "ASSESSMENT"){
          step.data = data;
          step.max = data.max;
        } else {
          _.extend(step, data);
        }
        return StepsService.save(step)
          .$promise
          .then(step => $location.url('/management/steps/' + step.id + '/edit'))
          .then(() => GlobalMessagesService.display({text: $i18next('create-step:success.create'), type: 'success'}))
          .catch(handleErrors);
      });
  };
};

angular.module('app')
  .controller('CreateStepController', CreateStepController);
