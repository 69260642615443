const CourseDetailsController = function(
  $location,
  $q,
  $i18next,
  $stateParams,
  CoursesService,
  StepsService,
  computeAssessmentMaxScore,
  UtilsService,
  GroupsService,
  GlobalMessagesService,
  orderByFilter,
  hasAnyRole,
  $rootScope
  ) {
  this.errors = {};
  this.search = '';
  const coursePromise = CoursesService.get({courseId: $stateParams.id}).$promise
      .then(
        course => this.course = course.toJSON(),
        () => $q.reject(this.errors.course = true));
  const computeMaxScore = courseSteps => _.sumBy(courseSteps, courseStep => {
    if (courseStep.step.type != 'ASSESSMENT') return 0;
    return computeAssessmentMaxScore(courseStep.step.questions);
  });

  coursePromise
    .then(() => StepsService.getForCourse({id: this.course.id}).$promise)
    .then(courseSteps => this.courseSteps = courseSteps.map(s => s.toJSON()), () => $q.reject(this.errors.steps = true))
    .then(courseSteps => {
      this.courseMaxScore = computeMaxScore(courseSteps);
      this.getStudentsByCourse();
    });

  const flagIfAlreadyAdded = stepsPage => {
    stepsPage.content = stepsPage.content.map(step => {
      step.alreadyAdded = (this.courseSteps || [])
        .reduce((acc, courseStep) => acc || courseStep.step.id === step.id, false);
      return step;
    });
    return stepsPage;
  };
  this.searchStepsForGrid = (config, callback) => {
    this.errors.stepsList = false;
    return StepsService.get(UtilsService.customParamEzgrid(config))
      .$promise
      .then(flagIfAlreadyAdded, () => $q.reject(this.errors.stepsList = true))
      .then(stepsPage => callback(stepsPage.content, stepsPage.totalElements, stepsPage.totalElements));
  };
  this.addStep = step => CoursesService.addStepToCourse({courseId: this.course.id}, {step: step.id})
    .$promise
    .then(courseStep => this.courseSteps = this.courseSteps.concat(courseStep))
    .then(() => this.stepsGridApi.ajax.reload())
    .then(() => GlobalMessagesService.display({text: $i18next('course-details:success.addStep'), type: 'success'}))
    .catch(() => GlobalMessagesService.display({text: $i18next('course-details:errors.addStep'), type: 'danger'}));

  this.moveStepUp = step => {
    const idx = this.courseSteps.indexOf(step);
    if (idx <= 0) {
      throw 'cannot move this step up';
    }
    this.courseSteps[idx - 1].position += 1;
    step.position -= 1;

    this.courseSteps = orderByFilter(this.courseSteps, 'position');

    this.saveStepOrder();
  };
  this.moveStepDown = step => {
    const idx = this.courseSteps.indexOf(step);
    if (idx == -1 || idx >= this.courseSteps.length - 1) {
      throw 'cannot move the last step down';
    }
    this.courseSteps[idx + 1].position -= 1;
    step.position += 1;

    this.courseSteps = orderByFilter(this.courseSteps, 'position');

    this.saveStepOrder();
  };

  this.removeStep = courseStep => {
    const idx = this.courseSteps.indexOf(courseStep);
    this.courseSteps.splice(idx, 1);
    this.courseSteps.forEach((step, index) => step.position = index + 1);

    const removedStep = CoursesService.removeStep({courseId: this.course.id, step: courseStep.step.id}, {}).$promise.then(response=>{
      this.stepsGridApi.ajax.reload();
      return response;
    });

    return removedStep.$promise;
  };

  this.rename = newTitle => CoursesService.rename({courseId: this.course.id}, newTitle).$promise;

  this.changeSuccessThreshold = successThreshold => CoursesService
    .changeSuccessThreshold({courseId: this.course.id, threshold: successThreshold}, null)
    .$promise;

  this.changeDescription = newDescription => CoursesService
    .changeDescription({courseId: this.course.id}, newDescription)
    .$promise;

  this.changeFailMessage = newFailMessage => CoursesService
    .changeFailMessage({courseId: this.course.id}, newFailMessage)
    .$promise;

  this.changeSucceedMessage = newSucceedMessage => CoursesService
    .changeSucceedMessage({courseId: this.course.id}, newSucceedMessage)
    .$promise;

  this.changeEstimated = newEstimated => CoursesService
    .changeEstimated({courseId: this.course.id}, newEstimated)
    .$promise;

  this.changeImage = (imageId, imageUrl) => CoursesService
    .changeImage({courseId: this.course.id}, {imageId}).$promise
    .then(() => {
      this.imageUrl = imageUrl;
      this.course.image = {id: imageId, url: imageUrl};
    });

  this.deleteImage = (imageId) => CoursesService.deleteImage({courseId: this.course.id}, {imageId}).$promise
    .then(() => {
      this.imageUrl = null;
      this.course.image = {};
    });

  this.deleteSummaryFile = (fileId) => CoursesService.deleteSummaryFile({courseId: this.course.id}, {imageId: fileId}).$promise
    .then(() => {
      this.summaryFileUrl = null;
      this.course.summaryFile = {};
    });

  this.changeSummaryFile = (summaryFileId, summaryFileUrl) => {
    return CoursesService
      .changeSummaryFile({courseId: this.course.id}, {imageId: summaryFileId}).$promise
      .then(() =>{
        this.summaryFileUrl = summaryFileUrl;
        this.course.summaryFile = {id: summaryFileId, url: summaryFileUrl}
      });
  }

  this.changePossibleAttempts = newPossibleAttempts => CoursesService
    .changePossibleAttempts({courseId: this.course.id, "att": newPossibleAttempts}, newPossibleAttempts)
    .$promise;

  this.deleteCourse = (course) => {
    const $promise = CoursesService.delete({courseId: course.id}).$promise;
    $promise.then(() => course.deleted = true);
    $promise.catch(() => GlobalMessagesService.display(
      {text: $i18next('manage-courses:errors.delete'), type: 'danger'}
    ));
    return $promise.then(()=> this.confirmDeleteModal.hide())
      .then($location.url('/management/courses'))
      .then(() => GlobalMessagesService.display({text: $i18next('course-details:success.deleteCourse'), type: 'success'}))
      .catch(() => GlobalMessagesService.display({text: $i18next('course-details:errors.deleteCourse'), type: 'danger'}));
  };

  this.sortableConfiguration = {
    accept: () => true,
    allowDuplicates: false,
    clone: false,
    additionalPlaceholderClass: 'list-group-item',
    orderChanged: () => {
      this.courseSteps.forEach((courseStep, index) => courseStep.position = index + 1);
      this.saveStepOrder();
    }
  };

  this.saveStepOrder = () => {
    this.permutationPromise = CoursesService.permuteSteps(
      {courseId: this.course.id},
      this.courseSteps.map(courseStep => ({position: courseStep.position, step: courseStep.step.id}))
    ).$promise;
  };
  this.counterRequirement = 0;
  this.addRequirement = tag => {
    if (this.counterRequirement === 0) {
      this.counterRequirement++;
      return CoursesService.addRequirement({
        courseId: $stateParams.id,
        requiredCourseName: tag.name
      }, null).$promise;
    } else {
      return false;
    }
  }

  this.removeRequirement = () => {
    if (this.counterRequirement > 0) this.counterRequirement--;
    return CoursesService.addRequirement({
      courseId: $stateParams.id,
      requiredCourseName: ''
    }, null).$promise;
  }

  this.loadCourses = $query => CoursesService.findCoursesAutocomplete({search: $query}).$promise;

  if ((hasAnyRole(['ADMIN','EDITOR', 'SALES']))) {
    this.req = CoursesService.getRequirement({courseId: $stateParams.id});
  }
  /*
    Sharing related stuff
   */
  this.tryToShare = tag => CoursesService.share({
    courseId: $stateParams.id,
    groupId: tag.id
  }, null).$promise;

  this.tryToUnshare = $tag => CoursesService.unshare({
    courseId: $stateParams.id,
    groupId: $tag.id
  }, null).$promise;

  this.loadGroups = $query => GroupsService.query({query: $query}).$promise;

  if ((hasAnyRole(['ADMIN','MANAGER', 'SALES']))) {
    this.sharings = CoursesService.sharings({courseId: $stateParams.id});
  }

  /*
    Course labelling related stuff
   */
  if ((hasAnyRole(['ADMIN','EDITOR', 'SALES']))) {
    this.categories = CoursesService.categories({courseId: $stateParams.id});
  }
  this.loadCategories = ($query) => CoursesService.loadCategories({search: $query}).$promise;

   this.tryToLabel = tag => {
     CoursesService.label({courseId: $stateParams.id, category:tag.name}, null);
   };
   this.tryToUnlabel = tag => {
    CoursesService.unlabel({courseId: $stateParams.id, category: tag.name}, null);
   };
   this.changeUrl = (file) => {
    return file ? 'rest/secure/files/' + file.id + '/download' : null;
  };

    this.getStudentsByCourse = function () {
      coursePromise
        .then(() => {
          this.imageUrl = this.changeUrl( this.course.image);
          this.summaryFileUrl = this.changeUrl( this.course.summaryFile);
        })
        .then(() => CoursesService.getStudentsByCourse({courseId: $stateParams.id}).$promise)
        .then(students => {
          this.students = students.map(obj => {
            let newObj = obj;
            newObj.completion = Number((obj.completion * 100).toFixed(2).replace(/\.?0+$/, "")); // handle trailing decimals
            newObj.stars = Number((obj.score).toFixed(2).replace(/\.?0+$/, ""));
            newObj.certification = (obj.certified) ? "Certified" : "";
            return newObj;
          })
        });
    };

  this.revokeAccess = user => {
    CoursesService.revokeAccess({courseId: this.course.id, userId: user.userId})
      .$promise
      .then(() => GlobalMessagesService.display({text: $i18next('course-details:success.revokeCourse'), type: 'success'}))
      .then(() => this.students.splice(this.students.indexOf(user), 1))
      .catch(() => {
        GlobalMessagesService.display({text: $i18next('course-details:errors.revokeCourse'), type: 'danger'});
      });
  };

   this.saveCroppedImage = () => {
     $rootScope.$broadcast('crop',{});
  };

  this.saveNewImage = (id) => CoursesService.changeImage({courseId: this.course.id}, {imageId: id}).$promise;
};

angular.module('app')
  .run((editableOptions , editableThemes) => {
    editableThemes.elearning = {
      // https://github.com/vitalets/angular-xeditable/blob/master/src/js/themes.js
      formTpl:     '<form class="" role="form"></form>',
      noformTpl:   '<span class=""></span>',
      controlsTpl: '<div class="" ng-class="{\'error\': $error}"></div>',
      inputTpl:    '',
      errorTpl:    '<div class="" data-ng-if="$error" data-ng-bind="$error"></div>',
      buttonsTpl:  '<span class=""></span>',
      submitTpl:   '<button type="submit" class="btn btn-primary"><span></span></button>',
      cancelTpl:   '<button type="button" class="btn" ng-click="$form.$cancel()"><span></span></button>'
    };
    editableOptions.theme = 'elearning';
  })
  .controller('CourseDetailsController', CourseDetailsController);
