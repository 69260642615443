angular.module('app').factory('ThemesService', $resource =>
  $resource('rest/secure/management/themes/:themeId', {id: '@id'}, {
    manageable: {method: 'GET', url: 'rest/secure/management/themes'},
    create: {method: 'POST', url: 'rest/secure/management/themes'},
    rename: {method: 'POST', url: 'rest/secure/management/themes/:themeId/name/:name'},
    listUsersWithTheme: {method: 'GET', url: 'rest/secure/management/themes/:themeId/users', isArray: true},
    addUserToTheme: {method: 'POST', url: 'rest/secure/management/themes/users/:userId'},
    removeUserFromTheme: {method: 'POST', url: 'rest/secure/management/themes/users/:userId'},
    delete: {method: 'DELETE', url: 'rest/secure/management/themes/:id/delete'},
    changeImage: {method: 'GET', url: 'rest/secure/management/themes/:id/image/:imageId'}
  })
);
