/* The work here is inspired from this snippet: http://bootsnipp.com/snippets/featured/fancy-bootstrap-checkboxes */

const FancyCheckboxComponent = ({
  templateUrl: './fancy-checkbox.html',
  bindings: {
    checked: '=model',
    onChange: '&',
    contextualClass: '=contextualClass'
  },
  controller: function() {
    this.toggleChecked = function() {
      this.checked = !this.checked;
      this.onChange({$value: this.checked});
    };
    this.keyPress = () => {
      event.preventDefault();
    }
  },
  transclude: true
});

angular.module('app').component('fancyCheckbox', FancyCheckboxComponent);
