const DEFAULT_TYPE = 'info';
const DEFAULT_DURATION = 5000;
/**
 * GlobalMessages service - used to display general messages into UI
 */
angular.module('app')
  .service('GlobalMessagesService', ($q, $timeout) => {
    const messages = [];
    const callbacks = [];

    /**
     * Subscribe a new listener and return an unsubscribe function
     * @param callback
     * @returns {Function} unsubscribe
     */
    const onMessage = (callback = angular.noop) => {
      callbacks.push(callback);
      return () => {
        const position = callbacks.indexOf(callback);
        if (position !== -1) {
          callbacks.splice(position, 1);
        }
      };
    };

    /**
     * Remove a message from the messages list
     * @param message
     */
    const remove = message => {
      const position = messages.indexOf(message);
      if (position !== -1) {
        messages.splice(position, 1);
        message._removalDeferred.resolve();
      }
    };

    /**
     * Display a new message
     * @param label
     * @param title
     * @param type
     * @param duration
     */
    const display = ({text, title, type = DEFAULT_TYPE, duration = DEFAULT_DURATION} = {}) => {
      const _removalDeferred = $q.defer();
      const message = {title, text, type, duration, _removalDeferred, removalPromise: _removalDeferred.promise};
      messages.push(message);
      $timeout(remove.bind(null, message), duration);
      callbacks.forEach(callback => callback(message));
    };

    return {display, onMessage, remove};
  });
