angular.module('app').factory('ProgressService', $resource => $resource(
  'rest/secure/course/:courseId/step/:stepId/progress',
  {courseId: '@courseId', stepId: '@stepId'},
  {
    courseScore: {
      method: 'GET',
      url: 'rest/secure/course/:courseId/score'
    },
    courseProgress: {
      method: 'GET',
      isArray: true,
      url: 'rest/secure/courses/:courseId/progress'
    },
    updateCompletion: {
      method: 'POST',
      url: 'rest/secure/course/:courseId/completion'
    },
    updateCompletionInStep: {
      method: 'POST',
      url: 'rest/secure/course/:courseId/completionInStep'
    },
    saveAnswer: {
      method: "POST",
        url: 'rest/secure/course/:courseId/step/:stepId/progressInStep/:questionId/:questionIndex'
    },
    getCourseResult: {
      method: 'GET',
      isArray: true,
      url: 'rest/secure/course/:courseId/user/result'
    },
    resetProgress: {
      method: 'POST',
      url: 'rest/secure/course/:courseId/progressReset'
    }
  }
));
