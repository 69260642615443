/**
 * Directive used to display global messages
 */
const GlobalMessagesDirective = GlobalMessagesService => ({
  restrict: 'E',
  replace: true,
  templateUrl: './global-messages.html',
  controller: $scope => {
    $scope.messages = [];
    GlobalMessagesService.onMessage(message => {
      $scope.messages.push(message);
      message.removalPromise.then(() => {
        const position = $scope.messages.indexOf(message);
        if (position !== -1) {
          $scope.messages.splice(position, 1);
        }
      });
    });
  }
});

angular.module('app')
  .directive('globalMessages', GlobalMessagesDirective);
