/**
 * Allows one to pick a file he already uploaded.
 *
 * a callback onselect will be called once the user has chosen a filed. The callback will be passed these locals:
 * - $file: the file object as given by the server
 * - $url: an url pointing to the file
 *
 * usage:
 *
 * <code>
 *  <file-picker onselect="Course.changeImage($url);"></file-picker>
 * </code>
 */
angular.module('app').component('filePicker', {
  templateUrl: './file-picker.html',
  bindings: {
    onselect: '&'
  },
  controller: function(
    $i18next,
    GlobalMessagesService,
    FilesService,
    Upload,
    toDownloadFileUrlFilter,
    UtilsService,
    $rootScope
  ) {
    this.showPreview = true;
    this.searchFilesForGrid = (config, callback) => {
      let params = UtilsService.customParamEzgrid(config);
      _.extend(params, {"mimeTypes": $rootScope.mimeTypes});

      return FilesService.myFiles(params)
        .$promise
        .then(filesPage => callback(filesPage.content, filesPage.totalElements, filesPage.totalElements))
    };
    this.pickFile = file => this.onselect({
      $id: file.id ,
      $file: file,
      $url: toDownloadFileUrlFilter(file.id)}
    );

    this.onFileSelected = () => {
      const promise = Upload.upload({
        url: 'rest/secure/management/files',
        data: {file: this.image}
      }).then(response => {
        const fileId = response.data.id;
        GlobalMessagesService.display({text: $i18next('slider:form.success.uploadSucceeded'), type: 'success'});
        this.onselect({
          $promise: promise,
          $id: fileId,
          $file: response.data,
          $url: toDownloadFileUrlFilter(fileId)
        });
        return promise;
      }, ()  => GlobalMessagesService.display({text: $i18next('slider:form.errors.uploadFailed'), type: 'danger'}));
    }

  }
}).filter('isImage', () => mimeType => mimeType.match(/image\/.*/i));
