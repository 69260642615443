angular.module('app').directive('imgCrop', function (Upload, $i18next, CoursesService, UtilsService, GlobalMessagesService) {
  return {
    templateUrl: 'img-crop.html',
    scope: {
      url: '=',
      onSave: '&',
      aspectRatio: '=?'
    },
    link: ($scope, $element) => {
      let cropper;
      let image = $element.find("img")[0];

      image.onload = function () {
        initCropper(image);
      };

      const initCropper = (image) => {
        cropper = new Cropper(image, {
          responsive: true,
          ready() {
            cropper.options.aspectRatio = $scope.aspectRatio;
          }
        });
      };

      $scope.$on('crop',function(){
        saveCroppedImage();
      });

      const saveCroppedImage = () => {
        cropper.getCroppedCanvas().toBlob((blob) => {
          const formData = new FormData();
          formData.append('croppedImage', blob);

          uploadCroppedImage(blob);
        });
      };

      const uploadCroppedImage = (blob) => {
        let extension = blob.type.split('/').pop();
        blob.name = "cropped_" + Date.now() + '.' + extension;

        Upload.upload({
          url: 'rest/secure/management/files',
          data: {file: blob}
        }).then(response => {
            $scope.url = UtilsService.uploadedFilesPattern.replace('{ID}',response.data.id);
            $scope.onSave({id: response.data.id});
            GlobalMessagesService.display({text: $i18next('slider:form.success.uploadSucceeded'), type: 'success'});
        }, () => GlobalMessagesService.display({text: $i18next('slider:form.errors.uploadFailed'), type: 'danger'}));
      };

      $scope.$watch('url', function () {
        if(cropper) {
          cropper.replace($scope.url);
        }
      });
    }
  }
});
