angular.module('app')
  .config(($stateProvider) => {
    $stateProvider
      .state('main.manage.themes', {
        url: 'themes',
        controller: 'ManageThemesController as $ctrl',
        templateUrl: './manage-themes/manage-themes.html'
      })
      .state('main.manage.createTheme', {
        url: 'themes/create',
        controller: 'CreateThemeController as $ctrl',
        templateUrl: './create-theme/create-theme.html'
      })
      .state('main.manage.editTheme', {
        url: 'themes/:themeId',
        controller: 'EditThemeController as $ctrl',
        templateUrl: './edit-theme/edit-theme.html'
      });
  });
