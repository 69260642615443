angular.module('app')
  .config(($stateProvider) => {
    $stateProvider
      .state('main.audit', {
        url: 'audit',
        templateUrl: './audit.html',
        controller: 'AuditController as $ctrl'
      });
  })
  .controller('AuditController', function (CoursesService, UtilsService) {


    this.searchAuditForGrid = (config, callback, auditedClass) => {
      let params = UtilsService.customParamEzgrid(config);
      _.extend(params, {auditedClass : auditedClass});

      this.results = CoursesService.getAudit(params).$promise
        .then(auditPage => {
          return callback(auditPage.content, auditPage.totalElements, auditPage.totalElements)
        });
      return this.results;
    }

  });

