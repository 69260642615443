angular.module('app').config(($stateProvider, SecurityProvider) => {
  $stateProvider.state('main.manage', {
    abstract: true,
    template: '<ui-view />',
    url: 'management/',
    resolve: {
      security: SecurityProvider.hasAnyRole(['EDITOR', 'MANAGER', 'ADMIN'])
    }
  });
});

