const QcmComponent = {
    templateUrl: './matching-question.html',
    bindings: {
        question: '<',
        showAnswer: '<',
        currentQuestionNumber: '@',
        questionsCount: '@',
        onFinish: '&',
        api: '=',
        assessmentChanged: '&',
        givenAnswer: '<'
    },
    controller($scope, getShuffledArray, UtilsService) {
        this.answersList = [];
        this.questionsList = [];


        this.$onChanges = changes => {
            if (changes && changes.question) {
                this.buildQuestionList();
            }
        };
        this.buildQuestionList = () => {
            this.shuffledBeginnings = this.question.answers;
            this.ends = getShuffledArray(this.question.answers);
            this.questionsList = [];
            this.shuffledBeginnings.forEach((entry, key) => {
                this.questionsList.push({
                    index: key,
                    label: entry.leftValue,
                    allowTypes: ['answer'],
                    max: 1,
                    answers: [],
                    cssClass: 'width-100'
                });
            });

            this.answersList[0] = {
                label: "",
                allowedTypes: ["answer"],
                max: this.ends.length,
                answers: this.ends.map((entry) => ({
                    label: entry.rightValue,
                    type: "answer"
                }))
            };

            this.assessmentChanged();
        };
        this.isAnswerCorrect = (goodAnswers) => {
          if (typeof goodAnswers === "string") {
            goodAnswers = JSON.parse(goodAnswers);
          }
            let countTrueSelectedAnswers = 0;
            this.questionsList.forEach((question) => {
                if(question.answers.length){
                    let matchObject = UtilsService.find(goodAnswers, (gAnswer) => gAnswer.leftValue === question.label);
                    question.valid = matchObject ? question.answers[0].label === matchObject.rightValue : false;
                    if(question.valid){
                        countTrueSelectedAnswers++;
                    }
                } else {
                    question.valid = false;
                }
            });
            return (countTrueSelectedAnswers === goodAnswers.length);
        };

        this.onDragStart = (index) => {
            var crt = event.srcElement.cloneNode(true);
            this.crt = crt;
            crt.style.position = "absolute"; crt.style.width = "300px"; crt.style.left = "-100%";
            document.body.appendChild(crt);
            if(event.dataTransfer.setDragImage){
                event.dataTransfer.setDragImage(crt, 0, 0);
            }
        }
        this.dragoverCallback = (index, allowDrop) => allowDrop;
        this.onDragEnd = () => {
            $(this.crt).remove();
            this.assessmentDisabledBtn();
        };

        this.assessmentDisabledBtn = function() {
          let semafor = false;
          this.questionsList.forEach((question) =>  { if(!question.answers.length) semafor = true; })
          $scope.$emit("assessmentDisabledBtn", semafor);
        }

        this.onAnswerInsideQuestionMoved = (question) => {
            this.questionsList[question.index].answers = [];
        };

        this.getUsersAnswer = () => {
            let data = [];
            this.questionsList.forEach((question) => {
                if(question.answers.length){
                    question.answers.forEach((uAnswer) => {
                        console.log(question);
                        data.push({
                            "leftValue":question.label,
                            "rightValue":uAnswer.label
                        });
                    });
                }
                return question.answers[0];
            });
            return data;
        };
        const setGoodAnswersOnQuestions = (goodAnswers) => {
            //this method is applied when we have "givenAnswer".

            if(goodAnswers && this.api.state.showAnswer) {
                let givenAnswer = this.api.state.givenAnswer;
                this.questionsList.map((question) => {
                    let leftValue = question.label;
                    let foundAnswerObj = UtilsService.find(goodAnswers, answer => answer.leftValue === leftValue);
                    let rightValueGoodAnswers = foundAnswerObj ? foundAnswerObj["rightValue"] : undefined;
                    let userAnswer = givenAnswer ? UtilsService.find(givenAnswer, gAnswer => gAnswer.leftValue === leftValue) : undefined;
                    let rightValueUserAnswer = givenAnswer.length && userAnswer !== undefined ? userAnswer["rightValue"] : undefined;

                    if(rightValueUserAnswer){
                        question.answers.push({
                            label: rightValueUserAnswer
                        });
                    }
                    question.valid = rightValueGoodAnswers === rightValueUserAnswer;

                    return question;
                });
            }
        };
        _.extend(this.api, {
            setGoodAnswersOnShuffledAnswers: setGoodAnswersOnQuestions,
            getResult: () => {
                return {
                    isAnswerCorrect: this.isAnswerCorrect,
                    clientAnswer: this.getUsersAnswer()
                };
            }
        });

    }
};

angular.module('app').component('matchingQuestion', QcmComponent);

