angular.module('app').controller('IndexController', function($rootScope, $i18next, LanguageService, $location) {
    this.currentYear = new Date().getFullYear();
    this.showFooter = true;
    this.pageColorClass = "white";
    this.containerClass = "container-fluid";

    this.getLanguage = () => {
      let lg = LanguageService.getCurrentLanguage().substring(0,2);
      return lg;
  }

  this.isNotSecuredPage = () => {
    let result = $location.path() === '/legal-notes-fr' || '/legal-notes-en' || $location.path() === '/cookies-fr' && $location.path() === '/cookies-en';
    console.log("$location.path() = ", $location.path());
    return result;
  }


    $rootScope.$on("$stateChangeSuccess", (event, toState, toParams, fromState, fromParams) => {
      console.log("toParams, fromState, fromParams", toParams, fromState, fromParams)
        if(toState.name === "main.playCourse.step" || toState.name === "main.playCourse.continue"
          || toState.name === "main.playCourse.results" || toState.name === "main.manage.stepDetails"){
            this.showFooter = false;
            this.pageColorClass = "dark-blue";
        } else {
            this.showFooter = true;
            this.pageColorClass = "grey";
        }
        this.containerClass = toState.name.indexOf("main.manage") !== -1 && toState.name.indexOf("main.manage.stepDetails") == -1 ?
          "container" : "container-fluid";
    });
});
