/**
 * Default behaviour of buttons on click is submit, which is way too much hassle.
 * Just disable if type is not mentionned.
 *
 */

angular.module('app').directive('button', () => ({
  restrict: 'E',
  link: (scope, element, attributes) => {
    if (!attributes.type) {
      element.attr('type','button');
    }
  }
}));
