angular.module('app')
  .component('coursePanel', {
    templateUrl: './course-panel.html',
    bindings: {
      course: '<',
      completion: '<',
      manage: '<',
    },
    controller($state, hasAnyRole, $i18next, StepsService,  UtilsService) {
      if (hasAnyRole(['ADMIN','SALES','EDITOR','MANAGER'])) {
        this.coursesDisabled = false;
      } else {
        this.coursesDisabled = true;
      }
      this.continueCourse = () => {
        $state.go('main.playCourse.continue', {courseId: this.course.id});
      };
      this.label = {
        "reverse": 'course-panel:statusInProgress',
        "success": 'course-panel:statusValidated',
        "danger": 'course-panel:statusFailed'
      };
      this.cssClass = "reverse";
      this.computeCssClass = (data) => {
            if(data){
              if(data.completion === 1 && data.certification){
                this.cssClass = "success";
              } else if(data.completion === 1 && !data.certification){
                this.cssClass = "danger";
              }
            }
        return this.cssClass;
      }
      this.$onInit = this.$onChanges = (changes) => {
        if(changes && changes.completion){
          this.computeCssClass(this.completion);
        }
      }
    }
  });
