angular.module('promiseCoordinator', []).factory('Coordinator', function($q) {

  function Coordinator() {
    var internalPromise;
    var startCallbacks = [];
    var endCallbacks = [];
    var checkDone = function checkDone () {
      if (internalPromise.$$state.status > 0) {
        this.running = false;
        endCallbacks.forEach(function(callback){
          callback.call();
        });
      }
    }.bind(this);

    this.running = false;

    this.$getInternalPromise = function() {
      return internalPromise;
    };

    this.addPromise = function(promise) {
      if ( !promise.then && !promise.then && !promise.finally) {throw 'promiseCoordinator: invalid promise passed';}
      if (this.running == true) {
        internalPromise = $q.all( [internalPromise, promise.finally()] );
      } else {
        internalPromise = promise.finally();
        this.running = true;
        startCallbacks.forEach(function(callback){
          callback.call();
        });
      }
      internalPromise.finally(checkDone);
    };

    this.start = function(callback) {
      startCallbacks.push(callback);
    };
    this.end = function(callback) {
      endCallbacks.push(callback);
    };
  }

  return Coordinator;
});
