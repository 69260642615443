const ManageCourseController = function(UtilsService, CoursesService, $window) {
  this.errors = {};

  CoursesService.manageable().$promise.then(courses => {
    this.courses = _.sortBy(courses.content, 'name');
  });
};

angular.module('app')
  .controller('ManageCourseController', ManageCourseController);
