const SliderDirective = ($templateCache, $interpolate, ezPaths, baseUrl, sliderThemes, $window) => {
  const revealJSTemplate = $interpolate($templateCache.get('client/features/steps/slider/slider-content.html'));
/*
  const slideTemplateContent = `<section class="slide" data-markdown><script type="text/template">{{slide.content | rebaseFilesRelativeUrl}}</script></section>`;
*/
  const slideTemplateContent = '{{slide.content | rebaseFilesRelativeUrl}}';

  const slideTemplate = $interpolate(slideTemplateContent);
  return {
    restrict: 'E',
    templateUrl: './slider.html',
    scope: {
      slides: '<',
      theme: '<',
      api: '=?'
    },
    link: scope => {
      const slides = scope.slides
        .map(slide => slideTemplate({slide}))
        .join('\n');

      scope.iframeUrl = baseUrl + ezPaths.asset("html_sources") +"/reveal.html?content="+encodeURIComponent(slides);
      _.extend(scope.api, {
        isDone: () => true,
        getResult: () => ({score: 0, max: 0})
      });
      $window.onkeypress = (event) => {
        //Enter key
        if(event.keyCode === 13 ){
          if(!scope.api.isLastStep){
            scope.api.goToNextStep();
          } else {
            scope.api.goToResult();
          }
        }
      }
    }
  };
};

angular.module('app')
  .directive('slider', SliderDirective);

angular.module('app').factory('sliderThemes', (ezPaths, baseUrl) => {
  const availableThemes = [
    {
      name: 'sg',
      source: 'custom'
    },
    {
      name: 'beige',
      source: 'reveal'
    },
    {
      name: 'black',
      source: 'reveal'
    },
    {
      name: 'blood',
      source: 'reveal'
    },
    {
      name: 'league',
      source: 'reveal'
    },
    {
      name: 'moon',
      source: 'reveal'
    },
    {
      name: 'night',
      source: 'reveal'
    },
    {
      name: 'serif',
      source: 'reveal'
    },
    {
      name: 'simple',
      source: 'reveal'
    },
    {
      name: 'sky',
      source: 'reveal'
    },
    {
      name: 'solarized',
      source: 'reveal'
    },
    {
      name: 'white',
      source: 'reveal'
    }
  ];

  const api = {
    getCustomTheme(name) {
      return baseUrl + ezPaths.asset('reveal/sg/') + name + '.css';
    },
    getRevealTheme(name) {
      return baseUrl + ezPaths.vendor('../libs/revealjs/css/theme') + name + '.css';
    },
    getAvailableThemes() {
      return availableThemes;
    },
    getTheme(theme) {
      if (!theme) return this.getDefaultTheme();
      switch (theme.source) {
        case 'reveal':
          return this.getRevealTheme(theme.name);
        case 'custom':
          return this.getCustomTheme(theme.name);
        default:
          return this.getDefaultTheme();
      }
    },
    getDefaultTheme() {
      return this.getTheme({
        source: 'custom',
        name: 'sg'
      });
    }
  };

  return api;
});
