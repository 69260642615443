angular.module('app')
  .controller('LanguageController', LanguageController)
  .config($stateProvider => {
    $stateProvider.state('main', {
      abstract: true, // so we cannot transition to an empty content. See home state.
      url: '/',
      templateUrl: 'main.html',
      controller: function(ezUsers) {
        this.user = ezUsers;
      },
      controllerAs: '$ctrl'
    });
  });

function LanguageController($i18next, ezI18nService, $rootScope, ezUsers) {
  this.languagesList = [{
      lang: "en-US",
      label: "EN"
    }];
  let frLang = {
    lang: "fr-FR",
    label: "FR"
  };
  let frBourseLang = {
    lang: "frbourse-FRBOURSE",
    label: "Français"
  };

  this.languagesList.push(frLang);

  ezUsers.get(() => {
    let lang = ezUsers.language;
    if(lang !== null && lang === "frbourse-FRBOURSE"){
      this.languagesList.push(frBourseLang);
      this.languagesList = _.remove(this.languagesList, (v) => v.lang === "fr-FR");
    }
  });
  this.changeLanguage = function(language) {
    ezI18nService.changeLanguage(language, false);
    this.language = language;
    $rootScope.$emit("languageChanged", language);
  };
  this.isActive = function(language) {
    return this.language ? this.language === language
            : ezUsers.language ? ezUsers.language === language
              : language === 'en-US';
  }

}

angular.module('app').component('globalLoadingStatus', {
  // NOTE: ng-show seems to wait for CSS animations to complete ...?
  // ng-animate probably to blame here. Switching to ng-style in the meantime
    template: '<div class="global-loading-status" ng-style="{ display: $ctrl.loading || $ctrl.coordinator.running ? \'block\':\'none\' }">' +
    '<div class="bullets"><span>Loading</span>\n' +
    '<span class="l-1"></span>\n' +
    '<span class="l-2"></span>\n' +
    '<span class="l-3"></span>\n' +
    '<span class="l-4"></span>\n' +
    '<span class="l-5"></span>\n' +
    '<span class="l-6"></span>\n' +
    '</span></div>',
  controller: function($rootScope, promiseTrackDefaultOptions, loadingInterceptor) {
    this.loading = false;
    this.coordinator = loadingInterceptor.getCoordinator();

    $rootScope.$on(promiseTrackDefaultOptions.START_EVENT, () => this.loading = true);
    $rootScope.$on(promiseTrackDefaultOptions.END_EVENT, () => this.loading = false);
  }
});
