const ManageResultsController = function(UtilsService, CoursesService, $window, $scope) {
  var list = new Array();
  var selected = new Array();
  var testCheckbox = false;
  this.checkboxError=false;

  CoursesService.manageable().$promise.then(courses => {
    this.courses = _.sortBy(courses.content, 'name');
    this.courses.forEach(function (course) {
      course["isChecked"]=false;
    });
    list = this.courses;
});

    $scope.test = function(){
        for (var i = 0; i < list.length; i++) {
          if (list[i].isChecked) {
            testCheckbox = true;
            selected.push(list[i].id);
          }
    }

    if (!testCheckbox){
      alert("You need to select one course!");
    }else{
      testCheckbox=false;
      var s="coursesIds=";
      for(var y = 0; y < selected.length; y++){
        s +=selected[y];
        if(y+1 < selected.length){
          s +="&coursesIds=";
        }
      }

      CoursesService.courseCertification({coursesIds: s}).$promise
        .then(() => selected = []);
    }
  };

}
angular.module('app')
  .controller('ManageResultsController', ManageResultsController);
