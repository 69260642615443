const ForgottenPasswordController = function($q, $i18next, GlobalMessagesService, UsersService, getShortLocale) {
  this.iForgotMyPassword = username => {
    let params =  {
      username: username
    }
    params.lang = getShortLocale();
    UsersService.forgotPassword(params, null).$promise.then(
      () => GlobalMessagesService.display({text: $i18next('forgottenpassword:passwordRequestSent'), type: 'success'}),
      () => $q.reject(GlobalMessagesService.display({text: $i18next('forgottenpassword:passwordRequestError'), type: 'danger'}))
    );
  }
};

angular.module('app')
  .controller('ForgottenPasswordController', ForgottenPasswordController);
