const startCourseButton = {
    templateUrl: "./start-course-button.html",
    bindings: {
        completionData: '<',
        courseData: '<'
    },
    controller: function($state){
        let resumeCourse        = this.completionData && this.completionData.completion && this.completionData.completion < 1 && this.completionData.completion > 0;
        let startCourse         = this.completionData && this.completionData.completion === 0;
        let startCourseAgain    = this.completionData && this.completionData.completion === 1;

        if(startCourse){
            this.label = "course-panel:start";
        } else if(resumeCourse){
            this.label = "course-panel:continue";
        } else if(startCourseAgain){
            this.label = "course-panel:restart";
        } else {
            this.label = "course-panel:start";
        }

        this.action = (event) => {
            event.preventDefault();
            if(resumeCourse){
                $state.go('main.playCourse.continue', {courseId: this.courseData.id});
            } else {
                $state.go('main.playCourse.step', {courseId: this.courseData.id, stepIndex: 0});
            }
        }

    }
};
angular.module("app").component("startCourseButton", startCourseButton);