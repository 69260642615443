angular.module('app')
  .config($stateProvider => {
    $stateProvider
      .state('main.help', {
        url: 'help',
        controller: 'HelpController as $ctrl',
        templateUrl: './help.html'
      });
  })
  .controller('HelpController', function($location, $i18next) {

  });
