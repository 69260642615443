const filterCourses = ({
  templateUrl: './filter-courses.html',
  bindings: {
    label: '@',
    data: '=',
    selectModel: '<',
    onFilterChanged: '&'
  },
  controller(CoursesService, LanguageService, $rootScope){
    let selectedLanguage = LanguageService.getCurrentLanguage();
    let uniqCategoryNamesObj = selectedLanguage === "fr-FR" ? {"Aucune" : true} :{"None": true};
    $rootScope.$on("languageChanged", () => {
      selectedLanguage = LanguageService.getCurrentLanguage();
      this.categoriesOptions[0] = selectedLanguage === "fr-FR" ? "Aucune" :"None";
    });

      this.data.forEach((course) => {
        if(course.categories){
          course.categories.forEach((category) => {
            if(uniqCategoryNamesObj[_.capitalize(category.name)] === undefined){
              uniqCategoryNamesObj[_.capitalize(category.name)] = true;
            }
          });
        }
      });
      this.categoriesOptions = Object.keys(uniqCategoryNamesObj);

    this.filterCoursesByCategory = function(category) {
      if(category === "None" || category === "Aucune"){
        category = "";
      }
      CoursesService.getCoursesByCategory({search: category})
        .$promise
        .then(response => {
          this.data = _.sortBy(response.content, 'name');
          this.onFilterChanged();
        });
    }
  }
});

angular.module('app').component('filterCourses', filterCourses);
