angular.module('app').controller('EditGroupController', function (
  GroupsService,
  UtilsService,
  UsersService,
  CoursesService,
  $stateParams,
  $i18next,
  GlobalMessagesService
) {
  this.errors = {};
  GroupsService.get({groupId: $stateParams.groupId}).$promise
    .then((response) => {
      this.group = response;
      this.owner = response.owner ? [response.owner] : null;
    });
  GroupsService.listUsersInGroup({groupId: $stateParams.groupId}).$promise
    .then((response) => this.users = response)
    .then(response => this.getManagers(response));

  GroupsService.listCoursesInGroup({groupId: $stateParams.groupId}).$promise
    .then((response) => this.courses = response);

  const flagIfAlreadyAdded = usersPage => {
    usersPage.content = usersPage.content.map(user => {
      user.alreadyAdded = (this.users || [])
        .reduce((acc, groupUser) => acc || groupUser.id === user.id, false);
      return user;
    });
    return usersPage;
  };

  const flagCourseIfAlreadyAdded = coursesPage => {
    coursesPage.content = coursesPage.content.map(course => {
      course.alreadyAdded = (this.courses || [])
        .reduce((acc, groupCourse) => acc || groupCourse.id === course.id, false);
      return course;
    });
    return coursesPage;
  };

  this.getManagers = usersList => {
    this.managers = UtilsService.filter(usersList, user => user.role === 'MANAGER' || user.role === 'SALES').map( user => user.username);
  }

  this.searchUsersForGrid = (config, callback) => {
    this.errors.users = false;
    return UsersService.manageable(UtilsService.formatEzgridParams(config))
      .$promise
      .then(flagIfAlreadyAdded, () => $q.reject(this.errors.users = true))
      .then(usersPage => callback(usersPage.content, usersPage.totalElements, usersPage.totalElements))
      .catch(() => this.errors.users = true);
  };

  this.searchCoursesForGrid = (config, callback) => {
    this.errors.courses = false;
    return CoursesService.manageable(UtilsService.formatEzgridParams(config))
      .$promise
      .then(flagCourseIfAlreadyAdded, () => $q.reject(this.errors.courses = true))
      .then(coursesPage => callback(coursesPage.content, coursesPage.totalElements, coursesPage.totalElements))
      .catch(() => this.errors.courses = true);
  };

  this.rename = name => GroupsService.rename({groupId: $stateParams.groupId, name}, null).$promise;

  this.addUser = user => GroupsService.addUserToGroup({userId: user.id, groupId: $stateParams.groupId}, "")
    .$promise
    .then(() => this.users.push(user))
    .then(() => this.usersGridApi.ajax.reload())
    .then(() =>  this.getManagers(this.users))
    .then(() => GlobalMessagesService.display({text: $i18next('course-details:success.addStep'), type: 'success'}))
    .catch(() => GlobalMessagesService.display({text: $i18next('course-details:errors.addStep'), type: 'danger'}));

  this.removeUser = user => GroupsService.removeUserFromGroup({
    userId: user.id,
    groupId: $stateParams.groupId
  }, "").$promise
    .then(() => this.users.splice(this.users.indexOf(user), 1))
    .then(() => this.usersGridApi.ajax.reload())
    .then(() =>  this.getManagers(this.users))

  this.addCourse = course => GroupsService.addCourseToGroup({courseId: course.id, groupId: $stateParams.groupId}, "")
    .$promise
    .then(() => this.courses ? this.courses.push(course) : undefined)
    .then(() => this.coursesGridApi.ajax.reload())
    .then(() => GlobalMessagesService.display({text: $i18next('edit-group:success.addCourse'), type: 'success'}))
    .catch(() => GlobalMessagesService.display({text: $i18next('edit-group:errors.addCourse'), type: 'danger'}));

  this.removeCourse = course => GroupsService.removeCourseFromGroup({
    courseId: course.id,
    groupId: $stateParams.groupId
  }, "").$promise
    .then(() => this.courses.splice(this.courses.indexOf(course), 1))
    .then(() => this.coursesGridApi.ajax.reload());

  this.changeOwner = (tag, remove) => {
    let params = {
      groupId: $stateParams.groupId,
      ownerId: tag.id
    }
    if (remove) {
      params.remove = true;
      this.owner = null;
    }
    return GroupsService.changeOwner(params, null).$promise;
  };

  this.loadPossibleOwners = $query => GroupsService.findPossibleOwners({search: $query}).$promise;
});
