/**
 * The confirmator service allows you to confirm().
 *
 * Note: there should only be one instance of confirmator component on the page at any time.
 */
angular.module('app').component('confirmator', {
  templateUrl: './confirmator.html',
  controller: function($scope, confirmator) {
    this.confirmator = confirmator;
    $scope.$watch('$ctrl.modalApi', modalApi => confirmator.modalApi = modalApi);
    this.confirm = () => {
      this.modalApi.hide();
      confirmator.onConfirmClicked();
    };
    this.cancel = () => {
      this.modalApi.hide();
      confirmator.onCancelClicked();
    };
  }
}).factory('confirmator', function($q, $timeout) {
  const MILLISECONDS_NEEDED_FOR_EZMODAL_TO_CLOSE_PROPERLY = 500;
  return {
    modalApi: null,
    confirm(message) {
      this.defer = $q.defer();
      this.message = message;
      this.modalApi.show();
      return this.defer.promise;
    },
    onConfirmClicked() {
      $timeout(() => this.defer.resolve(), MILLISECONDS_NEEDED_FOR_EZMODAL_TO_CLOSE_PROPERLY);
    },
    onCancelClicked() {
      $timeout(() => this.defer.reject(), MILLISECONDS_NEEDED_FOR_EZMODAL_TO_CLOSE_PROPERLY);
    }
  };
});
