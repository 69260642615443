const PlayStepController = function(
  $log,
  $q,
  $state,
  $stateParams,
  ProgressService,
  userCourse,
  courseSteps,
  step,
  computeAssessmentMaxScore,
  courseStepsProgress,
  $scope,
  CoursesService,
  $timeout,
  hasAnyRole,
  UtilsService,
  $window,
  $rootScope) {
  this.course = userCourse.course;
  this.completion = userCourse.completion;
  this.courseSteps = courseSteps;
  this.stepIndex = Number($stateParams.stepIndex);
  this.isLastStep = courseSteps.length - 1 <= this.stepIndex;
  this.step = step;
  this.maxScore = this.step.max;
  this.stepApi = {};

  let stepProgress;
  $scope.disabledBtn = true;
  if (courseStepsProgress) {
    // For clients
    stepProgress = UtilsService.filter(courseStepsProgress, cs => cs.step.id === this.step.id)[0];
    this.assessmentDone = stepProgress && stepProgress.score >= this.maxScore && this.step.type == 'ASSESSMENT';
  } else {
    // For admins
    // TODO: this branching upon admin/clients having or not having course progress is annoying and hard to maintain. Refactor it (maybe use a virtual progress)
    this.assessmentDone = false;
    stepProgress = null;
  }

  this.goToNextStep = () => {
    let saveProgress = this.step.type === "ASSESSMENT";
    this.saveProgression(saveProgress).then(() =>
        $state.go('main.playCourse.step', {courseId: this.course.id, stepIndex: this.stepIndex + 1})
    );
  };
  this.goToPrevStep = (lastIndex) => {
     $window.comesFromPrev = true;
     $state.go('main.playCourse.step', {courseId: this.course.id, stepIndex: this.stepIndex - 1, assessmentId: null}).then(() => {
       $rootScope.$emit("prevDone", lastIndex);
     });
  };

  const unsubscriberListener = $rootScope.$on("prevDone", (event, lastIndex) => this.stepApi.defaultIndex = lastIndex);
  $timeout(() => {
    unsubscriberListener();
  });

  this.skipToNextStep = () => {
    $state.go('main.playCourse.step', {courseId: this.course.id, stepIndex: this.stepIndex + 1})
  };
  this.goToResult = () => {
    let saveProgress = this.step.type === "ASSESSMENT";
    this.saveProgression(saveProgress).then(() =>
      $state.go('main.playCourse.results', {courseId: this.course.id}, {reload: true})
    );
  };
  this.skipToResult = () => {
    $state.go('main.playCourse.results', {courseId: this.course.id}, {reload: true})
  };

  this.saveProgression = (ProgressNotSave) => {
    if (!this.stepApi.isDone()) {
      return $q.resolve();
    }
    if (!courseStepsProgress) {
      // only clients have courseStepsProgress
      return $q.resolve(1);
    }
    const {score, rejected} = this.stepApi.getResult();

    const absoluteCompletion = parseInt($stateParams.stepIndex, 10) + 1;
    const completion = Number((absoluteCompletion / courseSteps.length).toFixed(3));
    $log.log('updating completion to, stepindex, total', completion, absoluteCompletion, courseSteps.length);
   /* $analytics.eventTrack('progression', {
      score,
      rejected,
      completion,
      category: 'progression',
      label: 'stepProgression',
      stepId: this.step.id,
      courseId: this.course.id
    });
*/
    let totalScore = courseStepsProgress.reduce((scoreAccumulator, progress) => scoreAccumulator + progress.score, 0);
        let courseMaxScore = courseStepsProgress.reduce((stepAccumulator, step) => stepAccumulator + step.step.max, 0);
        let postData = {
            completion: completion,
            totalScore: totalScore,
            courseMaxScore: courseMaxScore,
            stepIndex: Number($stateParams.stepIndex)+1,
            questionIndex: 0,
            retryNumber: this.course.possibleAttempts ? userCourse.retryNumber + 1 : 0
        };

    const completionPromise = ProgressService.updateCompletion({courseId: this.course.id}, postData).$promise
      .then(() => this.completion = userCourse.completion = completion)
      .catch(() => $log.warn('completion not updated properly'));

    let completionAndProgressPromise;

    if(ProgressNotSave) {
      completionAndProgressPromise = $q.resolve(completionPromise);
    } else {
      const progressPromise = ProgressService.save({
        courseId: this.course.id,
        stepId: this.step.id
      }, {
        score,
        rejected
      }).$promise;
      completionAndProgressPromise = $q.all([completionPromise, progressPromise]);
    }


    if (!stepProgress) {
      courseStepsProgress.push(stepProgress = {step: this.step});
    }
    stepProgress.score = score;

    return completionAndProgressPromise ;
  };

  /*Resume from last question index*/
    if(!$stateParams.assessmentId && $window.comesFromPrev !== true){
      CoursesService.courseWithCompletion({courseId: $stateParams.courseId})
          .$promise
          .then(userCourse => {
            if(!this.step.deleted && this.step.type === "ASSESSMENT"){
              if(userCourse){
                if(userCourse.stepIndex > parseInt($stateParams.stepIndex)){
                  this.stepApi.setQuestion(0);
                } else {
                  /*!!!!!
                  Set last question index only if is NOT shuffle step, otherwise set to 0
                  !!!!!*/
                  this.stepApi.setQuestion(!this.step.shuffle ? (userCourse.questionIndex || 0) : 0);

                }
                let progressByStepId = UtilsService.find(courseStepsProgress, (progress) => progress.step.id === userCourse.stepId) || {};
                if(typeof progressByStepId !== "undefined"){
                  this.stepApi.setScore(progressByStepId.score || 0);
                }

              }
            } else if (!this.step.deleted && this.step.type === "PDF") {
              this.stepApi.setPage(userCourse.questionIndex || 0);
            }
          });
    }

  $scope.$on("validateAnswer", (ev, obj) => {
    this.stepApi.displayPagination();
    this.currentQuestionIndex = this.stepApi.getQuestionIndex();
    let stepIndex     = Number($stateParams.stepIndex);
    let questionIndex = this.currentQuestionIndex;

    saveProgress()
      .then(() => saveAnswer(obj))
      .then(() => updateCompletionInStep(stepIndex, questionIndex))
      .then(() => {
        /*When the user answers the question he cannot be able to return back on an answer that already has been answered.*/
        if(questionIndex === step.questions.length-1){
          questionIndex = 0;
          stepIndex ++;
        } else {
          questionIndex ++;
        }
      });
  });

  let saveProgress = () => {
    const {score, rejected} = this.stepApi.getResult();
    return ProgressService.save({courseId: this.course.id,stepId: this.step.id}, {score,rejected}).$promise
  };

  let saveAnswer = (obj) => {
    let answers = obj.answers;
    if(obj.answers.length === 0){
        answers = [{
            questionId: obj.questionId,
            questionPosition: this.stepApi.state.getCurrentQuestion()["position"],
            valid: obj.isAnswerCorrect
        }];
    }
    return ProgressService.saveAnswer({
      courseId: this.course.id,
      stepId: this.step.id,
      questionId: this.stepApi.state.getCurrentQuestion()["id"],
      questionIndex: this.currentQuestionIndex
    },{answers});
  };

  let updateCompletionInStep = (stepIndex, questionIndex) => {
    let completion = Number((($stateParams.stepIndex / courseSteps.length) + ((questionIndex + 1) / step.questions.length / courseSteps.length)).toFixed(3));
    const stepId = this.step.id;
    return ProgressService.updateCompletionInStep({
      courseId: this.course.id, "isOnRetry": $stateParams.assessmentId ? true : false
    },{completion, stepIndex, questionIndex, stepId}).$promise;
  };

  this.stepApi.goToNextStep = this.goToNextStep;
  this.stepApi.goToPrevStep = this.goToPrevStep;
  this.stepApi.goToResult = this.goToResult;
  this.stepApi.isLastStep = this.isLastStep;

  $scope.$on("assessmentLoaded", () => {
    if($stateParams.assessmentId){
      let questionIndex = _.findIndex(this.step.questions, v => v.id === parseInt($stateParams.assessmentId));
      this.stepApi.setQuestion(parseInt(questionIndex));
      this.stepApi.retry = true;
      let currentScore = UtilsService.find(courseStepsProgress, (entry) => entry.step.id === this.step.id).score;
      this.stepApi.setScore(currentScore);
    }
  });


  this.handleActionsAssessment = () => {
    this.stepApi.displayPagination();

    if (!this.stepApi.state.showAnswer) {
      this.stepApi.validateAnswer();
    }
  };

  $window.onkeypress = (event) => {
    //Enter key
    if (event.keyCode === 13 && $scope.disabledBtn == false) {
      switch (this.step.type){
        case "ASSESSMENT" :
          this.handleActionsAssessment();
            break;
        default:
              console.warn("No action for step type "+this.step.type)
              break;
      }
      $scope.$apply();
    }
  }

  $scope.$on('assessmentDisabledBtn', (ev, disabledParam) => { $scope.disabledBtn = disabledParam });
};

angular.module('app')
  .controller('PlayStepController', PlayStepController);
