const menu = ({
  templateUrl: './menu.html',
  bindings: {
    user: '='
  },
  controller(ezUsers){
    this.logout = function(){
      ezUsers.logout();
    }
  }
});

angular.module('app').component('menu', menu);

