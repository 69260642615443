const CONFLICT = 409;

angular.module('app').controller('CreateThemeController', function (
  $state,
  ThemesService,
  GlobalMessagesService,
  $i18next,
  $scope,
  $rootScope
) {
  this.theme = {
    users: []
  };

  this.resetErrors = () => {
    this.themeNameTaken = false;
    this.unknownError = false;
  };

  this.createTheme = () => {
    this.resetErrors();
    if (!this.createThemeForm.$valid) {
      return;
    }
    if(!this.theme.image) {
      GlobalMessagesService.display({text: $i18next('create-theme:form.errors.themeImageMandatory'), type: 'danger'})
    } else {
      return ThemesService.create(this.theme).$promise
        .then(themeCreated => {
          $state.go('main.manage.editTheme', {themeId: themeCreated.id})
        })
        .catch(error => {
          if (error.status === CONFLICT) {
            this.themeNameTaken = true;
          } else {
            this.unknownError = true;
          }
        });
    }
  };

  this.saveCroppedImage = () => $rootScope.$broadcast('crop', {});
  this.changeImage = (id) => this.theme.image = id;
});
