angular.module('app').component('uploader', {
  templateUrl: './uploader.html',
  bindings: {
    mimeTypes: '@',
    uploaderApi: '=',
    hideControls: '<',
    onSelect: '&',
    label: '@'
  },
  controller: function ($q, $rootScope) {
    // We use that defer to control the promise sent by showDialog()
    let defer;

    this.onFileSelected = (fileData) => {
      this.filePickerModal.hide();
      defer.resolve(fileData);
      defer = null;
      this.onSelect(fileData);
    };

    const showDialog = () => {
      setMimeTypeList(this.mimeTypes);
      defer = $q.defer();
      this.filePickerModal.show();
      return defer.promise;
    };

    const hideDialog = () => {
      defer.reject(this.filePickerModal.hide());
      defer = null;
    };

    const setMimeTypeList = mimeTypes => {
      let mimeTypesList = [];

      if (mimeTypes.includes('image')) {
        mimeTypesList.push('%image%');
      }
      if (mimeTypes.includes('video')) {
        mimeTypesList.push('%video%');
      }
      if (mimeTypes.includes('application')) {
        mimeTypesList.push('%application%');
      }

      $rootScope.mimeTypes = mimeTypesList;
    }

    const label = this.label || 'personalGallery';

    this.uploaderApi = {
      showDialog,
      hideDialog,
      label
    };
  }
});
