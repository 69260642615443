angular.module('app')
  .config($stateProvider => {
    $stateProvider
      .state('main.manage.users', {
        url: 'users',
        templateUrl: './manage-users/manage-users.html'
      })
      .state('main.manage.createUser', {
        url: 'users/create',
        templateUrl: './create-user/create-user.html'
      })
      .state('unlogged.newPassword', {
        url: '/newpassword',
        $hideLoggin: true,
        controller: 'NewPasswordController as $ctrl',
        templateUrl: './newpassword/newpassword.html'
      })
      .state('unlogged.forgottenPassword', {
        url: '/forgottenpassword',
        $hideLoggin: true,
        controller: 'ForgottenPasswordController as $ctrl',
        templateUrl: './forgottenpassword/forgottenpassword.html'
      })
      .state('unlogged.registerUsers', {
        url: '/register/:token?',
        $hideLoggin: true,
        templateUrl: './register/register.html',
        controller: function($timeout, $filter) {
          $timeout(() => {
            let path = $filter('ezAssetPath')('images/Logo-SG.svg');
            var $selector = $("#custom-login-panel .ez-login-page-content").first();
            $selector.prepend("<img src=" + path + " class=\"custom-login-logo\"/>");
          })
        }
      })
      .state('unlogged.registerUser', {
        url: '/register',
        $hideLoggin: true,
        templateUrl: './register/register.html',
        controller: function($timeout, $filter) {
          let path = $filter('ezAssetPath')('images/Logo-SG.svg');
          $timeout(() => {
            var $selector = $("#custom-login-panel .ez-login-page-content").first();
            $selector.prepend("<img src=" + path + " class=\"custom-login-logo\"/>");
          });
        }
  });
  });
