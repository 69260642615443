angular.module('app').controller('CourseOverviewController', function(
  CoursesService,
  $stateParams,
  courseSteps
) {
    this.courseSteps = courseSteps.map(courseStep => courseStep.step.name);
    CoursesService.courseWithCompletion({courseId: $stateParams.courseId}).$promise.then((response) => {
      this.course = response.course;
      this.completion = response;
    });
});
