/**
 * Directive that displays an interface to grant accesses to courses to a given user
 */
const GRANT_SUCCESS_KEY = 'manage-user-access:success.grantCourse';
const GRANT_FAIL_KEY = 'manage-user-access:errors.grantCourse';
const REVOKE_SUCCESS_KEY = 'manage-user-access:success.revokeCourse';
const REVOKE_FAIL_KEY = 'manage-user-access:errors.revokeCourse';
const REMOVE_PROGRESS_SUCCESS_KEY = 'manage-user-access:success.removeProgress';
const REMOVE_PROGRESS_FAIL_KEY = 'manage-user-access:errors.removeProgress';
const REMOVE_PROGRESS_CONFIRMATION = 'manage-user-access:removeProgressConfirmation';
const ManageUserAccessDirective = ($q, $i18next, GlobalMessagesService, UtilsService, CoursesService,
                                   UsersService, confirmator) => ({
  restrict: 'E',
  scope: {
    user: '<'
  },
  templateUrl: './manage-user-access.html',
  link: scope => {
    scope.errors = {};
    scope.api = {};

    scope.searchCoursesForGrid = (config, callback) => {
      scope.errors.courses = false;
      scope.errors.accessibleCourses = false;
      let params = UtilsService.customParamEzgrid(config);
      _.extend(params, {id : scope.user.id});
      return UsersService.getCoursesWithAccess(params).$promise
        .then(coursesPage => callback(coursesPage.content, coursesPage.totalElements, coursesPage.totalElements))
    };

    scope.$watch('user', () => {
      if (scope.api.coursesGrid) {
        scope.api.coursesGrid.ajax.reload();
      }
    });

    scope.grantAccess = courseAccess => {
      courseAccess.pending = true;
      scope.errors.grantAccess = false;
      CoursesService.grantAccess({courseId: courseAccess.course.id}, {userId: scope.user.id})
        .$promise
        .then(() => courseAccess.access = true)
        .then(() => GlobalMessagesService.display({text: $i18next(GRANT_SUCCESS_KEY), type: 'success'}))
        .catch(() => {
          courseAccess.access = false;
          GlobalMessagesService.display({text: $i18next(GRANT_FAIL_KEY), type: 'danger'});
        })
        .then(() => courseAccess.pending = false);
    };

    scope.revokeAccess = courseAccess => {
      courseAccess.pending = true;
      scope.errors.revokeAccess = false;
      CoursesService.revokeAccess({courseId: courseAccess.course.id, userId: scope.user.id})
        .$promise
        .then(() => courseAccess.access = false)
        .then(() => GlobalMessagesService.display({text: $i18next(REVOKE_SUCCESS_KEY), type: 'success'}))
        .catch(() => {
          courseAccess.access = true;
          GlobalMessagesService.display({text: $i18next(REVOKE_FAIL_KEY), type: 'danger'});
        })
        .then(() => courseAccess.pending = false);
    };

    scope.grantOrRevokeAccess = courseAccess => (courseAccess.access && scope.grantAccess || scope.revokeAccess)(courseAccess);

    scope.removeProgress = courseAccess => {
      CoursesService.removeProgress({courseId: courseAccess.course.id, userId: scope.user.id})
        .$promise
        .then(() => GlobalMessagesService.display({text: $i18next(REMOVE_PROGRESS_SUCCESS_KEY), type: 'success'}))
        .catch(() => {
          GlobalMessagesService.display({text: $i18next(REMOVE_PROGRESS_FAIL_KEY), type: 'danger'});
        })
    };

    scope.confirm = (courseAccess) => {
      confirmator.confirm($i18next(REMOVE_PROGRESS_CONFIRMATION)).then(() => {
            scope.removeProgress(courseAccess);
      })
    }
  }
});

angular.module('app')
  .directive('manageUserAccess', ManageUserAccessDirective);
